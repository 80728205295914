import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../contexts/auth';

export default function EditName(Props) {
  const { currentUser, userInfo, updateUserInfo } = useContext(AuthContext);
  const [nameEdit, setNameEdit] = useState(userInfo.name);

  const handleSubmit = () => {
    updateUserInfo({ name: nameEdit });
    Props.close();
  };

  return (
    <Grid item container xs={12} spacing={5} direction="column" alignItems="center">
      <Grid item>
        <Typography variant="h6">Edit Your Name!</Typography>
      </Grid>

      <Grid item>
        <TextField
          label="Name"
          variant="outlined"
          name="name"
          id="name"
          autoComplete="account-name"
          value={nameEdit}
          margin="none"
          onChange={e => setNameEdit(e.target.value)}
        />
      </Grid>

      <Grid item>Email: {userInfo.email}</Grid>

      <Grid item>
        <Button fullWidth color="primary" variant="contained" size="large" disableElevation onClick={handleSubmit}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
}
