import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useEventContext from '../../../hooks/useEventContext/useEventContext';
import useActivityContext from '../../../hooks/useActivityContext/useActivityContext';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ActionButton from './ActionButton.js';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1), //grid padding
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 'auto',
  },
}));

function ListView(props) {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = props.getFromSharedState({ stateName: 'currentIndex', defaultValue: 0 });

  // for getting width
  const ref = useRef(null);

  let objectList = props.objectList;
  let showObjectComponent = props.showObjectComponent;

  let numObjects = objectList.length;
  let numObjectsPerPage = 3;

  let nextPage = () => {
    if (numObjects <= currentIndex + numObjectsPerPage) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + numObjectsPerPage);
    }
  };

  let lastPage = () => {
    if (currentIndex < numObjectsPerPage) {
      setCurrentIndex(numObjects - numObjectsPerPage);
    } else {
      setCurrentIndex(currentIndex - numObjectsPerPage);
    }
  };

  return (
    <div style={{ backgroundColor: 'white', overflowY: 'scroll', width: '100%' }}>
      <Grid container direction="column" ref={ref}>
        <Grid item xs={8} container direction="row">
          {objectList.slice(currentIndex, currentIndex + 3).map((obj, index) => (
            <Grid key={index} item xs={4}>
              <Paper className={classes.paper}>{showObjectComponent(obj)}</Paper>
            </Grid>
          ))}
        </Grid>

        <Grid item xs={8} container direction="row">
          <Grid item xs={2}>
            <ActionButton variant="contained" fullWidth onClick={lastPage}>
              Back
            </ActionButton>
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={2}>
            <ActionButton variant="contained" fullWidth onClick={nextPage}>
              Next
            </ActionButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ListView;
