import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { functions } from '../../../services/firebase';

const editGroup = functions.httpsCallable('editGroup');
const useStyles = makeStyles(theme => ({}));

export default function GroupForm({ group, close }) {
  const [editGroupName, set_editGroupName] = useState(group.name);

  const handleSubmit = async () => {
    await editGroup({ name: editGroupName, groupId: group.id });
    if (close) {
      close();
    }
  };

  return (
    <Grid container style={{ width: '100%', margin: 0 }} spacing={5} direction="row" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h3">Edit Group</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Change Name</Typography>
        <TextField
          label="Edit Group Name"
          variant="outlined"
          name="groupName"
          id="groupName"
          autoComplete="off"
          value={editGroupName}
          margin="none"
          fullWidth
          onChange={e => set_editGroupName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button color="primary" fullWidth variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button color="secondary" fullWidth variant="contained">
          Delete Group
        </Button>
      </Grid>
    </Grid>
  );
}
