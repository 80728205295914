import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import ActionButton from './ActionButton.js';
import ModalWrapper from './ModalWrapper.js';

export default function ModalButton(Props) {
  return (
    <ModalWrapper buttonComponent={<ActionButton text={Props.text} />} title={Props.title}>
      {Props.children}
    </ModalWrapper>
  );
}
