import { Button, Grid, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { AuthContext } from '../../../contexts/auth';
import { functions } from '../../../services/firebase';
import AddPhotos from '../Albums/AddPhotos';
import GroupInviteForm from '../Groups/GroupInviteForm';
import ScreenLayout from '../UI/ScreenLayout';

const createGroup = functions.httpsCallable('createGroup');

const Welcome = ({ nextStep, userInfo }) => {
  return (
    <Grid container style={{ width: '100%', margin: 0 }} spacing={5} direction="row" alignItems="center">
      <Grid item container xs={12} justifyContent="center">
        <Typography variant="h2">Welcome To Dialograpy {userInfo.name}</Typography>
      </Grid>
      <Grid xs={12} item container alignItems="center" justifyContent="center">
        <Typography variant="h6"> Dialography lets your conversations with your family come to life!</Typography>
      </Grid>
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Button color="primary" variant="contained" fullWidth size="large" onClick={nextStep}>
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

//
const CreateCircle = ({ nextStep, userInfo }) => {
  const handleUpdate = async inviteList => {
    // alert(JSON.stringify(inviteList))
    let gid = await createGroup({ invites: inviteList, name: 'My Circle' });
    // alert(JSON.stringify(gid));
    nextStep();
  };

  return (
    <Grid container style={{ width: '100%', margin: 0 }} spacing={5} direction="row" alignItems="center">
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <GroupInviteForm value={[]} action={handleUpdate} />
      </Grid>
      {/* <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Button color="primary" variant="outlined" fullWidth size="large" onClick={nextStep}>
          Skip
        </Button>
      </Grid> */}
    </Grid>
  );
};

//

const AddPhotosStep = Props => {
  return (
    <Grid container style={{ width: '100%', margin: 0 }} spacing={5} direction="row" alignItems="center">
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <AddPhotos />
      </Grid>
      {/* <Grid item>
        <Typography variant="h2">Add Photos</Typography>
      </Grid>

      <ModalButton text={'Upload From My Device'} title={'Upload Photos'}>
        <UploadPhotos />
      </ModalButton>

      <ModalButton text={'Connect To Google Photos Album'} title={'Connect To Google Photos Album'}>
        <UploadPhotosGoogle />
      </ModalButton> */}

      <Grid item xs={12} style={{ margin: 10 }}>
        <Button fullWidth variant="outlined" size="large" onClick={Props.nextStep}>
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

const mySteps = {
  1: Welcome,
  2: CreateCircle,
  3: AddPhotosStep,
};

export default function Onboarding(Props) {
  const history = useHistory();
  const { currentUser, userInfo, updateUserInfo } = useContext(AuthContext);

  const [step, set_step] = useState(1);
  const nextStep = () => {
    if (step < 3) {
      set_step(x => x + 1);
    } else {
      history.push('/account');
    }
  };

  return <ScreenLayout>{React.createElement(mySteps[step], { nextStep: nextStep, userInfo: userInfo })}</ScreenLayout>;
}
