import React from 'react';
import { useLocation, useHistory } from 'react-router';
import { Hidden, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForward from '@material-ui/icons/ArrowForward';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const useStyles = makeStyles(theme => ({
  why_dialography: {
    fontFamily: 'Roboto',

    height: '100%',
    maxWidth: '1440px',
    margin: 'auto',
  },
  hero_text_box: {},
  hero_text: {
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '38px',
      lineHeight: '54px',
      padding: '50px 0px 50px 0px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '22px',
      paddingTop: '20px',
      paddingBottom: '30px',
      paddingLeft: '25px',
      paddingRight: '10px',
      lineHeight: '32px',
    },
    fontWeight: '300',
    fontFamily: 'Roboto',
    fontStyle: 'normal',

    margin: 'auto',
    textAlign: 'center',
  },
  underline_bold: {
    fontWeight: '700',
    textDecoration: 'underline',
  },
  bold: {
    fontWeight: '700',
  },

  hero_row: {
    width: '100vw',
    height: '100%',
    maxWidth: '1440px',
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },

  why_hero1: {
    backgroundRepeat: 'no-repeat',
    background: 'url(/why_hero1.png)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'block',
    [theme.breakpoints.between('md', 'xl')]: {
      width: '65%',
      minHeight: '450px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100vw',
      height: '300px',
    },
  },

  body_text_box1: {
    backgroundColor: '#438C83',
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontWeight: '100',
    height: '100%',
    [theme.breakpoints.between('md', 'xl')]: {
      width: 'calc(35% - 60px)',
      padding: '60px',
      fontSize: '26px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100%',
      padding: '30px',
      fontSize: '18px',
    },
    display: 'flex',
  },
  hero_raw_bar1: {
    backgroundColor: '#438C83',
    width: '100vw',
    height: '25px',
    maxWidth: '1440px',
  },

  why_hero2: {
    backgroundRepeat: 'no-repeat',
    background: 'url(/why_hero2.png)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',

    [theme.breakpoints.between('md', 'xl')]: {
      width: '65%',
      minHeight: '495px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100vw',
      height: '300px',
    },
  },

  body_text_box2: {
    backgroundColor: '#2D82B7',
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontWeight: '100',

    height: 'auto !important',
    [theme.breakpoints.between('md', 'xl')]: {
      width: 'calc(35% - 60px)',
      padding: '60px',
      fontSize: '26px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100%',
      padding: '30px',
      fontSize: '18px',
    },
    display: 'block',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  hero_raw_bar2: {
    backgroundColor: '#2D82B7',
    width: '100vw',
    height: '25px',
    maxWidth: '1440px',
  },

  why_hero3: {
    backgroundRepeat: 'no-repeat',
    background: 'url(/why_hero3.png)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'block',
    [theme.breakpoints.between('md', 'xl')]: {
      width: '65%',
      minHeight: '510px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100vw',
      height: '275px',
    },
  },

  body_text_box3: {
    backgroundColor: '#E96A37',
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontWeight: '100',
    height: '100%',
    minHeight: '100%',
    [theme.breakpoints.between('md', 'xl')]: {
      width: 'calc(35% - 60px)',
      padding: '60px',
      fontSize: '26px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100vw',
      padding: '30px',
      fontSize: '18px',
    },
    display: 'block',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  hero_raw_bar3: {
    backgroundColor: '#E96A37',
    width: '100vw',
    height: '25px',
    maxWidth: '1440px',
  },

  problem_statement: {
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F0C808',
    color: '#000000',

    fontWeight: '300',
    maxWidth: '1440px',
    [theme.breakpoints.between('md', 'xl')]: {
      width: '100vw',
      fontSize: '42px',
      lineHeight: '60px',
      paddingTop: '45px',
      paddingBottom: '45px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '22px',
      lineHeight: '30px',
      paddingTop: '25px',
      paddingBottom: '25px',
      paddingLeft: '15px',
      paddingRight: '20px',
      width: 'calc(100vw - 30px)',
    },
  },
  problem_small_button: {
    /* primary-500 */
    color: '#FFFFFF',
    backgroundColor: '#2D82B7',
    cursor: 'pointer',
    fontWeight: '500',
    borderRadius: '0px',
    display: 'flex',
    margin: 'auto',
    marginTop: '10px',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '13px',
    },
  },

  beta_signup: {
    gridArea: 'beta_signup',
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E9FFFC',
    color: '#000000',
    width: '100vw',
    maxWidth: '1440px',
    [theme.breakpoints.between('md', 'xl')]: {
      paddingTop: '25px',
      paddingBottom: '25px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: '25px',
      paddingBottom: '25px',
    },
  },
  beta_signup_sm_text: {
    fontFamily: 'Roboto',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '14px',
    },
    fontWeight: '700',
    marginBottom: '5px',
  },
  beta_signup_button: {
    backgroundColor: '#2D82B7',
    color: '#FFFFFF',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '46px',
      paddingLeft: '100px',
      paddingRight: '100px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '28px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },

    fontWeight: '500',

    display: 'block',
    justitfyContent: 'center',
    margin: 'auto',
    borderRadius: '0px',
  },
  beta_signup_button_arrow: {
    justitfyContent: 'center',
    margin: 'auto',
    alignItems: 'center !important',
    width: '1.1em',
    height: '1.1em',
  },
}));

export default function Home() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <Header />
      <div className={classes.why_dialography}>
        <div className={classes.hero_text_box}>
          <div className={classes.hero_text}>
            <Hidden smDown>
              <span className={classes.underline_bold}> Feeling connected is fundamental </span> to our sense of{' '}
              <span className={classes.underline_bold}>self</span>
              <br />
              and overall <span className={classes.underline_bold}>health</span>. This is especially true for seniors,{' '}
              <span className={classes.underline_bold}>with</span>
              <br />
              <span className={classes.underline_bold}>40% struggling </span> with some degree of isolation.
            </Hidden>
            <Hidden mdUp>
              <span className={classes.underline_bold}> Feeling connected is fundamental </span>
              to our sense of <span className={classes.underline_bold}>self</span> and overall{' '}
              <span className={classes.underline_bold}>health</span>. This is especially true for seniors,{' '}
              <span className={classes.underline_bold}>with </span>
              <span className={classes.underline_bold}>40% struggling </span> with some degree of isolation.
            </Hidden>
          </div>
        </div>

        <Hidden mdUp>
          <div className={classes.why_hero1}></div>
        </Hidden>
        <div className={classes.hero_row} style={{ backgroundColor: '#438C83' }}>
          <Hidden smDown>
            <div className={classes.why_hero1}></div>
          </Hidden>
          <div className={classes.body_text_box1}>
            Dialography is a platform that gives families and caregivers a shared environment that facilitates better
            conversations and outcomes.
            <br />
            <br />
            Through purposefully designed dialogs and experiences, our platform reduces the technical barriers
            encountered in other platforms.
          </div>
        </div>

        <Hidden smDown>
          <div className={classes.hero_raw_bar1}></div>
        </Hidden>

        <Hidden mdUp>
          <div className={classes.why_hero2}></div>
        </Hidden>
        <div className={classes.hero_row} style={{ backgroundColor: '#2D82B7' }}>
          <div className={classes.body_text_box2}>
            Through intelligently recommended activities, our platform reduces the barriers created by modern technology
            platforms in maintaining regularly healthy dialogs, while also building a valuable repository of stories
            through co-creation.
          </div>
          <Hidden smDown>
            <div className={classes.why_hero2}></div>
          </Hidden>
        </div>

        <Hidden smDown>
          <div className={classes.hero_raw_bar2}></div>
        </Hidden>

        <Hidden mdUp>
          <div className={classes.why_hero3}></div>
        </Hidden>
        <div className={classes.hero_row} style={{ backgroundColor: '#E96A37' }}>
          <Hidden smDown>
            <div className={classes.why_hero3}></div>
          </Hidden>
          <div className={classes.body_text_box3}>
            Dialography provides a shared environment through a real-time collaborative canvas and video discussion
            platform where seniors can connect and create with their loved ones. <br />
            <br />
            This canvas is explored together and populated intelligently through machine learning with meaningful
            artifacts and activities.
          </div>
        </div>

        <Hidden smDown>
          <div className={classes.hero_raw_bar3}></div>
        </Hidden>

        <div className={classes.problem_statement}>
          <Hidden smDown>
            <strong>Dialography</strong> gives families a <br />
            <span className={classes.underline_bold}>platform for the co-creation</span> of <br />
            meaning <span className={classes.underline_bold}>with their senior parents.</span>
          </Hidden>
          <Hidden mdUp>
            <strong>Dialography</strong> gives families a{' '}
            <span className={classes.underline_bold}>platform for the co-creation</span> of meaning{' '}
            <span className={classes.underline_bold}>with their senior parents.</span>
          </Hidden>
          <Button onClick={() => history.push('/features')} className={classes.problem_small_button}>
            Learn more about the Dialography features <ArrowForward />
          </Button>
        </div>

        <div className={classes.beta_signup}>
          <div className={classes.beta_signup_sm_text}>Get early access to the Dialography platform!</div>
          <Button onClick={() => history.push('/join_beta')} className={classes.beta_signup_button}>
            Get Beta Access <ArrowForward className={classes.beta_signup_button_arrow} />
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
