import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../contexts/auth';
import { functions } from '../../../services/firebase';

const useStyles = makeStyles(theme => ({}));
const createGroup = functions.httpsCallable('createGroup');

export default function NewGroup(Props) {
  const { currentUser, userInfo, updateUserInfo } = useContext(AuthContext);

  const [groupName, set_groupName] = useState('');

  const handleSubmit = async () => {
    let groupId = await createGroup({ name: groupName });
    Props.close();
  };

  return (
    <Grid item container xs={12} spacing={5} direction="column" alignItems="center">
      <Grid item>
        <Typography variant="h6">Create A New Group</Typography>
      </Grid>

      <Grid item>
        <TextField
          label="Group Name"
          variant="outlined"
          name="name"
          id="name"
          autoComplete="off"
          value={groupName}
          margin="none"
          onChange={e => set_groupName(e.target.value)}
        />
      </Grid>

      <Grid item>
        <Button fullWidth color="primary" variant="contained" size="large" disableElevation onClick={handleSubmit}>
          Create A Group
        </Button>
      </Grid>
      <Grid item>
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          size="large"
          disableElevation
          onClick={() => Props.close()}
        >
          Close
        </Button>
      </Grid>
    </Grid>
  );
}
