import { Button, Grid, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { AuthContext } from '../../../contexts/auth';
import ModalWrapper from '../UI/ModalWrapper.js';
import UploadPhotos from './UploadPhotos.js';
import UploadPhotosGoogle from './UploadPhotosGoogle.js';

export default function AddPhotos(Props) {
  const { userInfo } = useContext(AuthContext);

  let albumInfo = userInfo.albumInfo ?? {};
  let albumList = Object.keys(albumInfo).map(x => ({ id: x, ...albumInfo[x] }));

  return (
    <Grid item container xs={12} spacing={5} direction="column" alignItems="center">
      <Grid item>
        <Typography variant="h2">Add Photos</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">You have {albumList.length} albums:</Typography>
        <Typography variant="body1">{albumList.map(x => x.name).join(',')}</Typography>
      </Grid>
      <Grid item>
        <ModalWrapper
          title={'Upload Photos'}
          buttonComponent={
            <Button variant="outlined" color="primary" fullWidth size="large">
              Upload From My Device
            </Button>
          }
        >
          <UploadPhotos />
        </ModalWrapper>
      </Grid>

      {/* TURNING THIS OFF FOR THE MOMENT - NEED TO GET UPLOAD PROCESS FIXED SO WE CNA RESIZE
      <ModalWrapper
        title={'Connect To Google Photos Album'}
        buttonComponent={
          <Button variant="outlined" color="primary" fullWidth size="large">
            Connect To Google Photos Album
          </Button>
        }
      >
        <UploadPhotosGoogle />
      </ModalWrapper> */}
    </Grid>
  );
}
