// @flow

import React from 'react';
import styled from 'styled-components';

const Text = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
`;

export default (props: any) => <Text {...props} />;
