import { Grid, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { AuthContext } from '../../../contexts/auth';
import ActionButton from '../UI/ActionButton.js';
import ModalButton from '../UI/ModalButton.js';
import PageHeading from '../UI/PageHeading';
import ScreenLayout from '../UI/ScreenLayout';
import AddPhotos from './AddPhotos.js';
import AlbumList from './AlbumList';

export default function ManageAlbums() {
  const history = useHistory();
  const { userInfo } = useContext(AuthContext);
  let albumInfo = userInfo.albumInfo ?? {};
  let albumList = Object.keys(albumInfo).map(id => ({ id: id, ...albumInfo[id] }));

  return (
    <ScreenLayout>
      {/* <Header /> */}
      <PageHeading title={'Manage Albums'} />
      {/* content */}
      <Grid container spacing={5} direction="row" alignItems="center">
        <Grid item xs={12}>
          <ModalButton text="Add Photos" title="Add Photos">
            <AddPhotos />
          </ModalButton>
          <ActionButton text={'Back'} onClick={() => history.goBack()} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4">Your Albums</Typography>

          <AlbumList albumList={albumList} />
        </Grid>
        <Grid item xs={12}>
          <ModalButton text="Add Photos" title="Add Photos">
            <AddPhotos />
          </ModalButton>

          <ActionButton text={'See Albums'} onClick={() => history.push('/albumslist')} />

          <ActionButton text={'Back'} onClick={() => history.goBack()} />
        </Grid>
      </Grid>

      {/* <Footer /> */}
    </ScreenLayout>
  );
}
