import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/analytics';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_ADMIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_GA_ID,
};

firebase.initializeApp(config);
const auth = firebase.auth();
const storage = firebase.storage();
const firestore = firebase.firestore();
const functions = firebase.functions();
const analytics = firebase.analytics();
// if (process.env.USE_EMULATORS) {
// auth.useEmulator('http://localhost:9099');
// firestore.useEmulator('localhost', 8080);
// functions.useEmulator('localhost', 5001);
// storage.useEmulator('localhost', 9199);

// }

var googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export { auth, firestore, storage, analytics, functions, googleAuthProvider };

// Enable offline firestore
// db.enablePersistence({ synchronizeTabs: true }).catch(function(err) {
//   console.log(err);
//   if (err.code === 'failed-precondition') {
//     // Multiple tabs open, persistence can only be enabled
//     // in one tab at a a time.
//   } else if (err.code === 'unimplemented') {
//     // The current browser does not support all of the
//     // features required to enable persistence
//   }
// });
