import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import useDialogContext from '../../hooks/useDialogContext/useDialogContext';

import NerdAlert from '../ActivityHandler/NerdAlert';
import Jokes from '../ActivityHandler/Jokes';
import MultiBox from '../ActivityHandler/MultiBox';
import ArtBrowser from '../ActivityHandler/ArtBrowser';
import PhotoBrowser from '../ActivityHandler/PhotoBrowser';
import YoutubeWatch from '../ActivityHandler/YoutubeWatch';
import Questions from '../ActivityHandler/Questions';
import SurveyActivity from '../ActivityHandler/SurveyActivity';

import Piano from '../ActivityHandler/Piano';

//

//
type ActivityContextType = {
  showActivity: boolean;
};

export const ActivityContext = createContext<ActivityContextType>();

export function ActivityProvider({ children }) {
  // HANDLE PARTICIPANTS IN THE ACTIVITY
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const [currentActivity, setCurrentActivity] = useState({ name: null });
  const { dialog, lastAction, addAction } = useDialogContext();

  const activities = {
    Questions: {
      component: Questions,
      component_name: 'Questions',
      button_label: 'Questions',
      text: 'Topic based Conversation',
      logo: 'topicbased_dialog.jpg',
      color: '#74A186',
    },
    PhotoBrowser: {
      component: PhotoBrowser,
      component_name: 'PhotoBrowser',
      button_label: 'Photo Browser',
      text: 'Look at Photos Together',
      logo: 'photobased_dialog.jpg',
      color: '#F0C808',
    },
    YoutubeWatch: {
      component: YoutubeWatch,
      component_name: 'YoutubeWatch',
      button_label: 'YoutubeWatch',
      text: 'Watch a Video Together',
      logo: 'videobased_dialog.jpg',
      color: '#2D82B7',
    },
    SurveyActivity: {
      component: SurveyActivity,
      component_name: 'SurveyActivity',
      button_label: 'Survey Activity',
      text: 'Complete a survey',
      logo: 'survey_dialog.jpg',
      color: '#B54248',
    },
  };

  // HANDLE CLOSE ACTIVITY
  let closeActivity = () => {
    addAction({ action: 'closeActivity' });
  };
  useEffect(() => {
    if (lastAction) {
      if (lastAction['action'] == 'closeActivity') {
        setCurrentActivity({ name: null });
      }
    }
  }, [lastAction]);

  // monitor for activity changes
  useEffect(() => {
    if (lastAction.activity) {
      // alert("in ActivityHandler.." + JSON.stringify(lastAction));
      if (lastAction.activity != currentActivity.name) {
        setCurrentActivity({ name: lastAction.activity });
      }
    }
  }, [lastAction.activity]);

  //
  let openActivity = ({ name }) => {
    addAction({ activity: name, action: 'open' });
    setCurrentActivity({ name: name });
  };

  // register events
  let registerEvent = payload => {
    // Need to remove all nulls before saving this, otherwise firestore error
    let payloadClean = Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != null));

    if (currentActivity) {
      addAction({ type: 'activity', activity: currentActivity.name, payload: payloadClean });
    }
  };

  return (
    <ActivityContext.Provider
      value={{ showActivity, setShowActivity, closeActivity, registerEvent, currentActivity, openActivity, activities }}
    >
      {children}
    </ActivityContext.Provider>
  );
}
