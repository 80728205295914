import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export default function SurveyCard(props) {
  let survey = props.survey;

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader title={survey.name} />

      <CardMedia
        component="img"
        height={props.height ? props.height : '150px'}
        image={survey.image} //"https://picsum.photos/id/"+getRandomInt(500)+"/200/300"}
        alt=""
      />

      <CardActions disableSpacing>
        <Button fullWidth variant="outlined" onClick={props.viewCallBack}>
          Open
        </Button>
        {/* <IconButton aria-label="share">
   Share
  </IconButton> */}
      </CardActions>
    </Card>
  );
}
