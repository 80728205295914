import React from 'react';
import { Grid, Typography, Paper, Card, CardHeader, CardActions, CardContent, Button, Chip } from '@material-ui/core';

export default function UserList({ users }) {
  return (
    <>
      {users.map(z => (
        <Chip key={z.id} label={z.name} variant="outlined" />
      ))}
    </>
  );
}
