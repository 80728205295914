// @flow

import React, { useState, useEffect, useRef, useMemo } from 'react';
import type { SurveyMaterialFormat, AutocompleteRequestFunction } from '../../material-survey-format.js.flow';
import SurveyQuestion from '../SurveyQuestion';
import { Dialog } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextIcon from '@material-ui/icons/KeyboardArrowRight';
import CompleteIcon from '@material-ui/icons/Check';
import styled from 'styled-components';
import evaluateExpression from 'surveyjs-expression-eval';
import validateQuestion from '../../hooks/use-question-answer/validators.js';
import QuestionContext from '../QuestionContext';
import { visibility } from '@material-ui/system';
// import Artifact from "../../../../../ArtifactPage/Artifact";

const scrollToElement = idOrElm => {
  const elm = typeof idOrElm === 'string' ? document.getElementById(idOrElm) : idOrElm;
  if (elm) {
    elm.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }
};

const SurveyActions = styled.div`
  display: flex;
  justify-content: ${({ onlyOnePage }) => (!onlyOnePage ? 'space-between' : 'space-around')};
`;

type Props = {
  form: SurveyMaterialFormat,
  variant?: 'flat' | 'paper',
  autocompleteRequest?: AutocompleteRequestFunction,
  onFileUpload?: File => Promise<string>,
  noActions?: boolean,
  completeText?: any,
  onFinish?: Object => any,
  onQuestionChange?: (questionId: string, newValue: any, answers: Object) => any,
  defaultAnswers?: Object,
};

export default function Survey({
  form,
  onFileUpload,
  noActions,
  completeText,
  variant = 'paper',
  onFinish = () => null,
  autocompleteRequest,
  onQuestionChange = () => null,
  defaultAnswers = {},
  userData,
  registerAction,
  registerActionHandler,
}: Props) {
  const [currentPage, setCurrentPageHook] = useState(0);
  const [answerMap, setAnswerMapHook] = useState(defaultAnswers);

  const setCurrentPage = pagenum => {
    if (registerAction) {
      registerAction({ type: 'surveyPage', page: pagenum, answermap: answerMap });
    }
    setCurrentPageHook(pagenum);
  };

  const setAnswerMap = payload => {
    if (registerAction) {
      registerAction({ type: 'surveyAnswerMap', answermap: payload });
    }
    setAnswerMapHook(payload);
  };

  useEffect(() => {
    if (registerActionHandler) {
      registerActionHandler('surveyAnswerMap', payload => {
        let action = payload['action'];
        // alert("got payload " + JSON.stringify(payload))
        if (action && action['type'] == 'surveyAnswerMap') {
          setAnswerMapHook(action['answermap']);
        }
      });
      registerActionHandler('surveyPage', payload => {
        let action = payload['action'];
        if (action && action['type'] == 'surveyPage') {
          setCurrentPageHook(action['page']);
        }
      });
    }
  }, []);

  const [debugDialog, set_debugDialog] = useState(false);

  const [failingQuestions, changeFailingQuestions] = useState([]);
  const surveyDiv = useRef(null);

  const questions = form.questions || form.pages[currentPage].elements;
  const visibleQuestions = questions.filter(q =>
    q.visibleIf === undefined ? true : evaluateExpression(q.visibleIf, answerMap)
  );

  let firstPage, lastPage;
  if (form.questions) {
    // single page survey
    firstPage = true;
    lastPage = true;
  } else {
    firstPage = currentPage === 0;
    lastPage = currentPage === form.pages.length - 1;
  }

  const onlyOnePage = firstPage && lastPage;

  const validatePage = () => {
    const fqs: Array<{
      question: Object,
      text: string,
    }> = [];
    for (const question of visibleQuestions) {
      if (question.isRequired && answerMap[question.name] === undefined) {
        fqs.push({
          question,
          text: 'This question is required!',
        });
        continue;
      }
      if (answerMap[question.name] !== undefined) {
        const failingValidator = (question.validators || []).find(
          v => !validateQuestion(v, answerMap[question.name], answerMap)
        );
        if (failingValidator) {
          fqs.push({
            question,
            text: failingValidator.text,
          });
          continue;
        }
      }
    }

    changeFailingQuestions(fqs);

    if (fqs.length > 0) {
      return fqs[0];
    } else {
      return {};
    }
  };

  // TODO complex survey validator logic
  const pageComplete = true;

  return (
    <React.Fragment>
      {/* {(answerMap["artifact"])?(
        <React.Fragment>

        <Artifact artifactId={answerMap["artifact"]["id"]} height="20vh" /> 
        </React.Fragment>
      ):(null)} */}

      <div style={{ height: '100%' }} ref={surveyDiv}>
        <Grid style={{ height: '100%' }} container direction="row" justify="space-between" alignItems="center">
          <Grid item xs={12}>
            {visibleQuestions.map(q => (
              <QuestionContext.Provider
                key={q.name}
                value={{
                  error: (failingQuestions.find(fq => fq.question.name === q.name) || {}).text,
                  containerStyleType: variant,
                }}
              >
                <SurveyQuestion
                  question={{ ...q, defaultAnswer: defaultAnswers[q.name] }}
                  onFileUpload={onFileUpload}
                  onChangeAnswer={(newAnswer: any) => {
                    const newAnswerMap = {
                      ...answerMap,
                      [q.name]: newAnswer,
                    };
                    setAnswerMap(newAnswerMap);
                    onQuestionChange(q.name, newAnswer, newAnswerMap);

                    // if (registerAction) {
                    //   registerAction({"type":"surveyAnswerChange",
                    //   "change":{"question":q.name,
                    //   "newAnswer":newAnswer,
                    //   "newAnswerMap":newAnswerMap}})
                    // }

                    // registerAction({"type":"surveyAnswer","question":q.name,"newAnswer":newAnswer,"newAnswerMap":newAnswerMap})
                  }}
                  autocompleteRequest={autocompleteRequest}
                  answerMap={answerMap}
                  userData={userData}
                  registerAction={registerAction}
                  registerActionHandler={registerActionHandler}
                />
              </QuestionContext.Provider>
            ))}
          </Grid>
          <Grid item xs={12}>
            {!noActions && (
              // <SurveyActions onlyOnePage={onlyOnePage}>
              <ButtonGroup fullWidth>
                {!onlyOnePage && (
                  <Button
                    style={firstPage ? { display: 'none' } : null}
                    disabled={firstPage}
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    <BackIcon />
                    Prev
                  </Button>
                )}
                <Button
                  size="large"
                  style={!lastPage || !pageComplete ? { display: 'none' } : null}
                  onClick={() => {
                    const { question: failingQuestion, text } = validatePage();
                    if (failingQuestion) {
                      scrollToElement(failingQuestion.name);
                    } else {
                      onFinish(answerMap);
                    }
                  }}
                  disabled={!lastPage || !pageComplete}
                >
                  Next
                  {/* {(completeText) ? (
              completeText
            ) : (
              <>
                <CompleteIcon style={{ marginRight: 4 }} />
                Complete
              </>
            )} */}
                </Button>
                {!onlyOnePage && (
                  <Button
                    style={!pageComplete || lastPage ? { display: 'none' } : null}
                    onClick={() => {
                      const { question: failingQuestion, text } = validatePage();
                      if (failingQuestion) {
                        scrollToElement(failingQuestion.name);
                      } else {
                        scrollToElement(surveyDiv.current);
                        setCurrentPage(currentPage + 1);
                      }
                    }}
                    // display={(!pageComplete || lastPage)?("none"):(null)}
                    disabled={!pageComplete || lastPage}
                  >
                    Next
                    <NextIcon />
                  </Button>
                )}
              </ButtonGroup>
              // </SurveyActions>
            )}
            <hr />
            <Dialog open={debugDialog} onClose={() => set_debugDialog(false)}>
              questions:
              <pre>{JSON.stringify(questions, false, 2)}</pre>
              answerMap
              <pre>{JSON.stringify(answerMap, false, 2)}</pre>
              <Button size="small" onClick={() => set_debugDialog(false)}>
                close
              </Button>
            </Dialog>
            {/* <Button size="small" onClick={() => set_debugDialog(true)}>
              show debug
            </Button> */}
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
