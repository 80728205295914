import React, { useState } from 'react';
import { TextField, Button, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Twitter, Instagram, LinkedIn } from '@material-ui/icons/';

const useStyles = makeStyles(theme => ({
  footer: {
    fontFamily: 'Roboto',
    display: 'grid',
    gridArea: 'footer',
    [theme.breakpoints.between('md', 'xl')]: {
      gridTemplateAreas: `
                  'footer_nav_bottom footer_contact_us '
              `,
      gridTemplateColumns: '40% 60%',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      gridTemplateAreas: `
  		            'footer_nav_bottom '
  		        `,
      gridTemplateColumns: '100%',
    },
    gridTemplateRows: '1fr',
    width: '100vw',
    height: '100%',
    maxWidth: '1440px',
    margin: 'auto',
    backgroundColor: '#16332F',
    paddingTop: '30px',
    paddingBottom: '30px',
    bottom: '0',
  },
  footer_nav_bottom: {
    [theme.breakpoints.between('md', 'xl')]: {
      marginLeft: '120px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginLeft: '80px',
    },
    gridArea: 'footer_nav_bottom',
    display: 'flex',
    flexDirection: 'column',
    color: '#FFFFFF',
  },
  footer_contact_us: {
    gridArea: 'footer_contact_us',
    paddingTop: '10px',
    height: '100%',

    display: 'flex',
    flexDirection: 'column',
  },
  footer_contact_us_text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#FFFFFF',
  },
  footer_brand_text: {
    fontFamily: 'Sonsie One',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '22px',
    lineHeight: '28px',
    alignItems: 'center',
    display: 'flex',
  },
  footer_nav_links: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '15px',
    lineHeight: '24px',
    paddingLeft: '25px',
  },
  footer_social_links: {
    lineHeight: '24px',
    paddingLeft: '25px',
    '& :focus, & :hover, & :visited, & :link, & :active': {
      color: '#FFFFFF',
    },
  },
  footer_contact_us_email: {
    marginBottom: '10px',
  },
  contact_us_inputbox: {
    backgroundColor: '#FFFFFF',
    width: '600px',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D82B7',
      borderWith: '10px',
      borderRadius: '0px',
    },
    '& .MuiOutlinedInput-input': {
      color: '#2D82B7',
    },
    '& .MuiInputLabel-root': {
      color: '#737581',
      fontSize: '12px',
    },
  },
  footer_contact_us_button_row: {
    width: '600px',
    position: 'relative',
    display: 'flex',
    flexFlow: 'row-reverse',
  },
  footer_contact_us_button: {
    color: '#FFFFFF',
    backgroundColor: '#2D82B7',
    padding: '5px',
    margin: '10px',
    borderRadius: '0px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '24px',
  },
}));

export default function Footer() {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const formData = { email, message, contactForm: true };
  const handleSubmit = async () => {
    // alert(JSON.stringify(formData));

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    };
    await fetch('https://us-central1-dialography-29b8b.cloudfunctions.net/betaApplication', requestOptions);

    setSuccess(true);
  };

  return (
    <footer style={{ display: 'block' }}>
      <div className={classes.footer}>
        <div className={classes.footer_nav_bottom}>
          <div className={classes.footer_brand_text}>Dialography</div>
          <div className={classes.footer_nav_links}>hello@dialography.com</div>
          {/* <div className={classes.footer_nav_links}>About Us</div>
          <div className={classes.footer_nav_links}>Blog</div>
          <div className={classes.footer_nav_links}>Jobs</div>
          <div className={classes.footer_nav_links}>Help Center</div>
          <div className={classes.footer_nav_links}>Privacy & Terms</div>
          <div className={classes.footer_nav_links}>Press Centre</div>
          <div className={classes.footer_nav_links}>Sitemap</div> */}
          <div className={classes.footer_nav_links}>&copy; Copyright {new Date().getFullYear()}</div>
          <div className={classes.footer_social_links}>
            <a href="https://twitter.com/dialography" target="_blank">
              <Twitter style={{ paddingRight: '5px' }} />
            </a>
            <a href="https://www.linkedin.com/company/dialography-com" target="_blank">
              <LinkedIn style={{ paddingRight: '5px' }} />
            </a>
            <a href="https://www.instagram.com/dialography_com" target="_blank">
              <Instagram />
            </a>
          </div>
        </div>
        <Hidden smDown>
          <div className={classes.footer_contact_us}>
            {success ? (
              <div className={classes.footer_contact_us_text}> Thank You For Your Message</div>
            ) : (
              <div className={classes.footer_contact_us_text}> Contact Us</div>
            )}

            <div className={classes.footer_contact_us_email}>
              <TextField
                label="Email"
                variant="outlined"
                className={classes.contact_us_inputbox}
                name="email"
                id="email"
                required
                size="small"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className={classes.footer_contact_us_message}>
              <TextField
                label="Message"
                variant="outlined"
                className={classes.contact_us_inputbox}
                name="message"
                id="message"
                required
                multiline
                rows={4}
                value={message}
                onChange={e => setMessage(e.target.value)}
              />
            </div>
            <div className={classes.footer_contact_us_button_row}>
              <Button onClick={handleSubmit} className={classes.footer_contact_us_button}>
                {success ? 'Sent!' : 'Send'}
              </Button>
            </div>
          </div>
        </Hidden>
      </div>
    </footer>
  );
}
