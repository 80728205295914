import React, { useState } from 'react';
import QuestionContainer from '../QuestionContainer';
import styled from 'styled-components';
import QuestionText from '../QuestionText';
import useQuestionAnswer from '../../hooks/use-question-answer';

export default ({ question, onChangeAnswer, actionManager }: { question: any, onChangeAnswer: Function }) => {
  const [{ answer, error }, changeAnswer] = useQuestionAnswer(question, onChangeAnswer, '', actionManager);

  return (
    <QuestionContainer question={question} error={error} answered={answer !== ''}>
      {question.text}
    </QuestionContainer>
  );
};
