import React, { useContext } from 'react';
import { auth } from '../../../services/firebase';
import { useLocation, useHistory } from 'react-router';
import { AuthContext, AuthProvider } from '../../../contexts/auth';
import { TextField, Grid, Typography, Hidden, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForward from '@material-ui/icons/ArrowForward';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const useStyles = makeStyles(theme => ({
  home: {
    fontFamily: 'Roboto',
    display: 'grid',
    [theme.breakpoints.between('md', 'xl')]: {
      gridTemplateAreas: `
  		            'hero_text_box hero_animation '
  		            'feature_bar feature_bar'
                  'problem_statement problem_statement'
  		            'stats_row stats_row'
                  'promo_video promo_video'
                  'beta_signup beta_signup'
                  'footer footer'
  		        `,
      gridTemplateColumns: '55% 45%',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      gridTemplateAreas: `
              'hero_text_box'
              'hero_animation '
              'feature_bar'
              'problem_statement'
              'stats_row'
              'promo_video'
              'beta_signup'
              'footer'
      `,
      gridTemplateColumns: '100%',
    },
    width: '100vw',
    height: '100%',
    maxWidth: '1440px',
    margin: 'auto',
  },
  hero_text_box: {
    display: 'grid',
    gridArea: 'hero_text_box',
    gridTemplateAreas: `
		            'hero_text hero_text_bar'
		            `,
    gridTemplateColumns: '92% 8%',
    gridTemplateRows: 'auto',
  },
  hero_text: {
    gridArea: 'hero_text',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '38px',
      lineHeight: '54px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '25px',
      paddingTop: '20px',
      paddingBottom: '30px',
      lineHeight: '42px',
    },
    fontWeight: '300',
    fontFamily: 'Roboto',
    fontStyle: 'normal',

    margin: 'auto',
    textAlign: 'center',
    paddingLeft: '30px',
  },
  hero_text_bar: {
    gridArea: 'hero_text_bar',
    height: '40%',
    width: '1px',

    backgroundColor: '#000000',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '35px',
  },
  hero_animation: {
    gridArea: 'hero_animation',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#A1DAD2',
    [theme.breakpoints.between('md', 'xl')]: {
      width: '100%',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100vw',
    },
    overflow: 'hidden',
  },

  feature_bar: {
    gridArea: 'feature_bar',

    backgroundColor: '#E9FFFC',

    height: '210px',
    display: 'flex',
    [theme.breakpoints.between('md', 'xl')]: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingLeft: '5px',
      paddingRight: '5px',
    },
  },
  feature_item: {
    margin: 'auto',
    color: '#000000',
    height: ' 140px',
    fontSize: '17px',
    textAlign: 'center',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '10px',
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingLeft: '8px',
      paddingRight: '7px',
    },
  },
  feature_item_bar: {
    gridArea: 'feature_item_bar',
    [theme.breakpoints.between('md', 'xl')]: {
      height: '40%',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      height: '60%',
    },
    width: '1px',

    backgroundColor: '#000000',
    margin: 'auto',
  },
  feature_item_image: {
    paddingBottom: '5px',
  },
  problem_statement: {
    gridArea: 'problem_statement',
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F4F5F7',
    color: '#000000',
    width: '100%',
    fontWeight: '300',

    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '42px',
      lineHeight: '56px',
      paddingTop: '35px',
      paddingBottom: '35px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '28px',
      lineHeight: '42px',
      paddingTop: '25px',
      paddingBottom: '25px',
    },
  },
  problem_small_button: {
    /* primary-500 */
    color: '#FFFFFF',
    backgroundColor: '#2D82B7',
    cursor: 'pointer',
    fontWeight: '500',
    borderRadius: '0px',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '13px',
    },
  },
  stats_row: {
    gridArea: 'stats_row',
    width: '100vw',
    maxWidth: '1440px',
    height: '100%',
    display: 'flex',
    [theme.breakpoints.between('md', 'xl')]: {
      backgroundRepeat: 'no-repeat',
      background: 'url(/home_stats_hero.png)',
      backgroundSize: 'fill',
    },
  },
  hero_heart: {
    backgroundSize: '700px 503px',
    [theme.breakpoints.between('md', 'xl')]: {
      width: '55%',
      height: '405px',
      paddingTop: '70px',
      marginTop: '50px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100%%',
      height: '205px',
      paddingTop: '10px',
      marginTop: '30px',
    },
  },

  stats_box: {
    backgroundColor: '#438C83',
    [theme.breakpoints.between('md', 'xl')]: {
      opacity: '0.8',
      width: '45%',
      paddingTop: '90px',
      paddingBottom: '50px',
      fontSize: '22px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100vw',
      paddingTop: '40px',
      paddingBottom: '20px',
      fontSize: '17px',
    },
    height: '405px',
    display: 'flex',
    flexDirection: 'column',
  },
  stats_box_item: {
    margin: 'auto',
    color: '#FFFFFF',

    fontWeight: '300',
    textAlign: 'center',
    gridArea: 'stats_box',
    backgroundColor: '#438C83',
  },
  stats_bar: {
    height: '1px',
    width: '60%',

    backgroundColor: '#FFFFFF',
    margin: 'auto',
  },

  underline_bold: {
    fontWeight: '700',
    textDecoration: 'underline',
  },
  bold: {
    fontWeight: '700',
  },
  promo_video: {
    width: '100%',
    margin: 'auto',
    display: 'grid',
    gridArea: 'promo_video',
  },
  promo_video_text: {
    margin: 'auto',
    color: '#000000',
    fontSize: '48px',
    textAlign: 'center',
    fontWeight: '500',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    margin: '10px',
  },
  beta_signup: {
    gridArea: 'beta_signup',
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E9FFFC',
    color: '#000000',
    width: '100vw',
    maxWidth: '1440px',
    [theme.breakpoints.between('md', 'xl')]: {
      paddingTop: '25px',
      paddingBottom: '25px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: '25px',
      paddingBottom: '25px',
    },
  },
  beta_signup_sm_text: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: '700',
    marginBottom: '5px',
  },
  beta_signup_button: {
    backgroundColor: '#2D82B7',
    color: '#FFFFFF',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '46px',
      paddingLeft: '100px',
      paddingRight: '100px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '28px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },

    fontWeight: '500',

    display: 'block',
    justitfyContent: 'center',
    margin: 'auto',
    borderRadius: '0px',
  },
  beta_signup_button_arrow: {
    justitfyContent: 'center',
    margin: 'auto',
    alignItems: 'center !important',
    width: '1.1em',
    height: '1.1em',
  },
}));

export default function Home() {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();

  return (
    <div>
      <Header />
      <div className={classes.home}>
        <div className={classes.hero_text_box}>
          <div className={classes.hero_text}>
            <Hidden smDown>
              <strong>Dialography</strong> <span className={classes.underline_bold}> is a platform </span> <br />
              that facilitates <span className={classes.underline_bold}>great </span>
              <span className={classes.underline_bold}>conversations </span>
              <br />
              between <span className={classes.underline_bold}>seniors</span> and the ones
              <br />
              that <span className={classes.underline_bold}>care for them</span>
              <br />
            </Hidden>
            <Hidden mdUp>
              <strong>Dialography</strong> <span className={classes.underline_bold}> is a platform </span>
              that facilitates <span className={classes.underline_bold}>great conversations</span>
              <span className={classes.underline_bold}></span> between{' '}
              <span className={classes.underline_bold}>seniors </span>
              and the ones that
              <span className={classes.underline_bold}> care for them</span>
              <br />
            </Hidden>
          </div>
          <Hidden smDown>
            <div className={classes.hero_text_bar}> </div>
          </Hidden>
        </div>
        <div className={classes.hero_animation}>
          <video autoPlay muted loop style={{ objectFit: 'fill', width: '100%' }}>
            <source src="/dialographguy.mp4" type="video/mp4" />
          </video>
        </div>

        <div id="FEATURES" className={classes.feature_bar}>
          <div className={classes.feature_item}>
            <div>
              <img src="/guided.png" className={classes.feature_item_image} />
            </div>
            Purposeful intelligently <br />
            guided conversations <br />
            and activities
          </div>
          <div className={classes.feature_item_bar}></div>
          <div className={classes.feature_item}>
            <div>
              <img src="/syncd.png" className={classes.feature_item_image} />
            </div>
            Synchronized shared <br />
            canvas for co-creation
          </div>
          <div className={classes.feature_item_bar}></div>
          <div className={classes.feature_item}>
            <div>
              <img src="/accessible.png" className={classes.feature_item_image} />
            </div>
            Accessibility designed <br />
            at the applications core
          </div>
          <Hidden smDown>
            <div className={classes.feature_item_bar}></div>
            <div className={classes.feature_item}>
              <div>
                <img src="/health.png" className={classes.feature_item_image} />
              </div>
              Focus on cognitive <br />
              health and connectedness
            </div>
            <div className={classes.feature_item_bar}></div>
            <div className={classes.feature_item}>
              <div>
                <img src="/person.png" className={classes.feature_item_image} />
              </div>
              Personalized shared <br />
              experiences
            </div>
          </Hidden>
        </div>
        <div className={classes.problem_statement}>
          Modern social platforms <br />
          are <span className={classes.underline_bold}>poorly suited </span> for <br />
          <span className={classes.underline_bold}>seniors</span> and their{' '}
          <span className={classes.underline_bold}>life stage</span>
          <br />
          <Button onClick={() => history.push('/why_dialography')} className={classes.problem_small_button}>
            Find out how Dialograhy helps <ArrowForward />
          </Button>
        </div>
        <div className={classes.stats_row}>
          <Hidden smDown>
            <div className={classes.hero_heart}></div>
          </Hidden>
          <div className={classes.stats_box}>
            <div className={classes.stats_box_item}>
              <span id="WHY" className={classes.bold}>
                {' '}
                68% of individuals{' '}
              </span>
              surveyed <br />
              agreed that the design of current <br />
              video chat platforms <span className={classes.bold}> need </span>
              <br />
              <span className={classes.bold}> significant improvement.</span>
              <br />
            </div>
            <div className={classes.stats_bar}></div>
            <div className={classes.stats_box_item}>
              <span className={classes.bold}>49% of individuals</span> surveyed faced <br />
              significant barriers <span className={classes.bold}>when communicating </span> <br />
              <span className={classes.bold}>with their senior family members digitally.</span>
            </div>
            <div className={classes.stats_bar}></div>
            <div className={classes.stats_box_item}>
              <span className={classes.bold}>55% of 35 to 55 year olds</span> expressed <br />
              interested in a platform dedicated to helping <br />
              maintain <span className={classes.bold}>healthy dialogs with their </span>
              <br />
              <span className={classes.bold}>senior parents.</span>
              <br />
            </div>
          </div>
        </div>
        <div className={classes.promo_video}>
          <iframe
            width="100%"
            height="500px"
            src="https://www.youtube.com/embed/V71jirTEyvQ"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      </div>
      <div className={classes.beta_signup}>
        <div className={classes.beta_signup_sm_text}>Get early access to the Dialography platform!</div>
        <Button onClick={() => history.push('/join_beta')} className={classes.beta_signup_button}>
          Get Beta Access <ArrowForward className={classes.beta_signup_button_arrow} />
        </Button>
      </div>
      <Footer />
    </div>
  );
}
