import React from 'react';
import { useState, useEffect, useRef } from 'react';
import useEventContext from '../../../hooks/useEventContext/useEventContext';
import useActivityContext from '../../../hooks/useActivityContext/useActivityContext';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import axios from 'axios';
import PhotosList from './components/PhotosList';
import PhotoView from './components/PhotoView';
import Loading from './components/Loading';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export default function VideoCard(props) {
  let videoId = props.video.youtubeid ?? props.video.id;

  return (
    <Card>
      <CardHeader title={props.video.name} />
      <CardMedia
        component="img"
        height={props.height ? props.height : '300px'}
        width={props.width ? props.width : '100px'}
        image={`https://img.youtube.com/vi/${videoId}/0.jpg`} // sddefault"https://picsum.photos/id/"+getRandomInt(500)+"/200/300"}
        alt=""
      />
      <CardContent></CardContent>
      <CardActions disableSpacing>
        <Button fullWidth variant="outlined" onClick={props.viewCallBack}>
          Open
        </Button>
        {/* <IconButton aria-label="share">
   Share
  </IconButton> */}
      </CardActions>
    </Card>
  );
}
