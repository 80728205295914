import { Box, Button, Drawer, Grid } from '@mui/material';
import React, { useState } from 'react';
import { SizeMe } from 'react-sizeme';
import useActivityContext from '../../hooks/useActivityContext/useActivityContext';
import useDialogContext from '../../hooks/useDialogContext/useDialogContext';
import MenuBar from '../MenuBar/MenuMaterial';
import ParticipantList from '../ParticipantList/ParticipantListMaterial';
import Room from '../Room/Room';

// Get the actual size of the full canvas
export default function CanvasWrapper() {
  return <SizeMe monitorHeight>{({ size }) => <Canvas size={size} />}</SizeMe>;
}

function SettingsBottomDrawer(Props) {
  const [open, set_open] = useState(false);
  const toggle = () => set_open(x => !x);

  return (
    <>
      <Button onClick={toggle} variant="outlined" color="secondary" size="large">
        Settings
      </Button>
      <Button onClick={() => alert(JSON.stringify(Props.dialog, false, 2))} size="small">
        Debug
      </Button>
      <Drawer anchor={'bottom'} open={open} onClose={toggle}>
        <Box
          sx={{ width: 'auto', minHeight: '50vh', backgroundColor: 'grey' }}
          role="presentation"
          onClick={Props.toggle}
        >
          <MenuBar />
          <Button variant="outlined" size="large" onClick={() => Props.set_participantFraction(x => x * 1.1)}>
            Larger Participants
          </Button>
          <Button variant="outlined" size="large" onClick={() => Props.set_participantFraction(x => x * 0.9)}>
            Smaller Participants
          </Button>

          <Button variant="outlined" size="large" onClick={toggle}>
            Close
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
function Canvas(Props) {
  const { currentActivity, closeActivity } = useActivityContext();

  const { isHost, dialog, group } = useDialogContext();
  const [participantFraction, set_participantFraction] = useState(0.2);

  const { height: windowHeight, width: windowWidth } = Props.size;
  // const { width, height } = Props.size;
  const paddingHeight = 10;
  let height = windowHeight - paddingHeight;
  let width = windowWidth;

  // const participantFraction = 0.2;

  const buttonHeight = 50;

  let blockWidth = height > width ? 12 : 6;
  let particantSize =
    height > width
      ? { height: (height - buttonHeight) * participantFraction, width: width }
      : { height: height - buttonHeight, width: width * participantFraction };
  let activitySize =
    height > width
      ? { height: (height - buttonHeight) * (1 - participantFraction), width: width }
      : { height: height - buttonHeight, width: width * (1 - participantFraction) };

  return (
    <Grid container direction="row" spacing={0} style={{ margin: 0, height: '100%', width: '100vw' }}>
      <Grid item container direction="row" spacing={0} style={{ height: height - buttonHeight }}>
        <Grid item style={{ height: activitySize.height, width: activitySize.width }}>
          <Room size={{ height: activitySize.height, width: activitySize.width }} />
        </Grid>
        <Grid item style={{ margin: 0, height: particantSize.height, width: particantSize.width }}>
          {/* Participants */}
          <ParticipantList height={particantSize.height} width={particantSize.width} />
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        style={{ height: buttonHeight, width: '100%', borderTop: '1px solid grey', background: 'white', zIndex: 99 }}
      >
        {currentActivity && currentActivity.name ? (
          <Button variant="outlined" onClick={closeActivity}>
            Close Activity
          </Button>
        ) : null}
        {isHost ? <div>Hey you are the host</div> : <div>you not the host</div>}
        {/* {(isHost && dialog.knocks && dialog.knocks.length>0)?("There are knocks"):('')} */}
        <SettingsBottomDrawer set_participantFraction={set_participantFraction} dialog={dialog} />
      </Grid>
      {/* 
      <Grid
        item
        container
        justifyContent="flex-end"
        style={{ height: buttonHeight, width: currentActivity ? width - 200 : width }}
      >
        
      </Grid> */}
    </Grid>
  );
}
