import { Button, Grid, Paper, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../../contexts/auth';
import { functions, firestore } from '../../../services/firebase';
import { useDocument } from 'react-firebase-hooks/firestore';

import AlbumList from '../Albums/AlbumList';
import ModalWrapper from '../UI/ModalWrapper';
import PageHeading from '../UI/PageHeading';
import ScreenLayout from '../UI/ScreenLayout';
import UserList from '../Users/List';
import AttachAlbumForm from './AttachAlbumForm';
import GroupForm from './EditGroup';
import GroupInviteForm from './GroupInviteForm';
import GroupUsersForm from './GroupUsersForm';

const updateGroupInvites = functions.httpsCallable('updateGroupInvites');

export default function GroupDetailScreen() {
  const { id } = useParams();
  const history = useHistory();
  const { currentUser, userInfo, updateUserInfo } = useContext(AuthContext);

  // GET GROUP FROM FIRESTORE
  const [group, set_group] = useState({});
  const groupRef = firestore.doc('groups/' + id);
  const [groupDoc, loading, error] = useDocument(groupRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  useEffect(() => {
    if (groupDoc) {
      let groupData = groupDoc.data();
      groupData['id'] = id;
      set_group(groupData);
    }
  }, [groupDoc]);
  //**** */

  let groupAlbumsInfo = group.albumInfo ?? {};
  let groupAlbumList = Object.keys(groupAlbumsInfo).map(id => ({ id, ...groupAlbumsInfo[id] }));

  let groupUsersInfo = group.userInfo ?? {};
  let groupUsersList = Object.keys(groupUsersInfo).map(id => ({ id, ...groupUsersInfo[id] }));

  const startDialog = () => {
    history.push('/new_dialog/' + id);
  };
  return (
    <ScreenLayout>
      <PageHeading title={group.name ?? 'Group'} />

      <Grid container direction="row">
        <Grid item xs={12}>
          <Button color="primary" size="large" variant="contained" fullWidth onClick={startDialog}>
            Start Dialog In This Group
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">Members</Typography>
          <Paper style={{ padding: 10, margin: 10 }}>
            <UserList users={groupUsersList ?? []} />

            <Grid item container xs={12} direction={'row-reverse'}>
              <Grid item xs={3}>
                <ModalWrapper
                  buttonComponent={
                    <Button size="small" variant="outlined">
                      Edit Users
                    </Button>
                  }
                >
                  <GroupUsersForm group={group} />
                </ModalWrapper>

                <ModalWrapper
                  buttonComponent={
                    <Button size="small" variant="outlined">
                      Invite People
                    </Button>
                  }
                >
                  <GroupInviteForm
                    value={group.invites ?? []}
                    action={async updatedValue => {
                      // await groupRef.set(updatedValue)
                      updateGroupInvites({ groupId: group.id, invites: updatedValue });
                      // await groupRef.update({ invites: updatedValue });
                    }}
                  />
                </ModalWrapper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">Attached Photo Albums</Typography>
          <Paper style={{ padding: 10, margin: 10 }}>
            <AlbumList albumList={groupAlbumList} />
            <Grid item container xs={12} direction={'row-reverse'}>
              <Grid item xs={3}>
                <ModalWrapper
                  buttonComponent={
                    <Button size="small" variant="outlined">
                      Attach Albums
                    </Button>
                  }
                >
                  <AttachAlbumForm group={group} />
                </ModalWrapper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <ModalWrapper
            buttonComponent={
              <Button size="small" variant="outlined" fullWidth onClick={() => history.push('group/' + id + '/edit')}>
                Edit Group Info
              </Button>
            }
          >
            <GroupForm group={group} />
          </ModalWrapper>
        </Grid>

        {/* <Grid>{JSON.stringify(group, false, 2)}</Grid> */}
      </Grid>
    </ScreenLayout>
  );
}
