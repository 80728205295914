import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Button from '@mui/material/Button';
import React, { useEffect, useRef } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const useStyles = makeStyles(theme => ({
  pageMat: {
    display: 'flex',
    background: 'rgba(0, 0, 0, 0.8)',
    width: '100vw',
    height: '100vh',
    zIndex: '1',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    left: '0',
    position: 'fixed',
  },
  objStyle: {
    opacity: '1',
    zIndex: '2',
    background: 'transparent',
    maxWidth: 'calc(100vw-100px)',
    maxHeight: 'calc(100vh-100px)',
    objectFit: 'contain',
  },
  imageControls: {
    position: 'absolute',
    top: '70px',
    left: '30px',
  },
  topButtons: {
    position: 'absolute',
    top: '70px',
    left: '70px',
  },
  bottomButtons: {
    position: 'absolute',
    bottom: '70px',
    left: '70px',
  },
  closePhotoButton: {
    zIndex: '12',
    backgroundColor: '#D0454C !important',
    color: '#FFFFFF',
    borderRadius: '0px !important',
    fontSize: '20px',
    fontWeight: 'normal',
    lineHeight: '23px',
    width: '196px',
    height: '55px',
    margin: '5px',
  },
  nextButton: {
    backgroundColor: '#276738 !important',
    color: '#FFFFFF',
    borderRadius: '0px !important',
    fontSize: '14px !important',
    fontWeight: 'normal',
    lineHeight: '23px',
    width: '196px',
    height: '55px',
    margin: '5px !important',
  },
}));

function ObjectView(props) {
  const classes = useStyles();
  const zoomRef = useRef();
  const [currentZoomState, setCurrentZoomState] = props.getFromSharedState({
    stateName: 'currentZoomState',
    defaultValue: {},
  });

  useEffect(() => {
    if (currentZoomState && zoomRef && zoomRef.current) {
      // alert("NEW currentZoomState" + JSON.stringify(currentZoomState))
      // let changeTo = {newPositionX:currentZoomState.positionX, newPositionY: currentZoomState.positionY, newScale: currentZoomState.scale}
      let changeTo = { x: currentZoomState.positionX, y: currentZoomState.positionY, scale: currentZoomState.scale };

      console.log('currentZoomState', JSON.stringify(changeTo));
      // zoomRef.current.resetTransform()
      zoomRef.current.setTransform(currentZoomState.positionX, currentZoomState.positionY, currentZoomState.scale);
      // zoomRef.current.setTransform(changeTo)
    }
  }, [currentZoomState]);

  return (
    <div className={classes.pageMat}>
      <div className={classes.topButtons}>
        <Button variant="contained" className={classes.closePhotoButton} onClick={props.backCallBack}>
          <CancelPresentationIcon style={{ paddingRight: '5px' }} /> Close Photo
        </Button>
      </div>
      <TransformWrapper
        ref={zoomRef}
        onZoom={x => {
          setCurrentZoomState(x.state);
        }}
      >
        <TransformComponent contentStyle={{ width: props.width * 0.5, height: props.height * 0.5 }}>
          <img src={props.photo.url} className={classes.objStyle} />
        </TransformComponent>
      </TransformWrapper>

      <div className={classes.bottomButtons}>
        <Button variant="contained" className={classes.nextButton} onClick={props.previousCallBack}>
          <ArrowBackIos fontSize="small" style={{ paddingRight: '5px' }} /> Previous Photo
        </Button>
        <Button variant="contained" className={classes.nextButton} onClick={props.nextCallBack}>
          Next Photo <NavigateNext style={{ paddingRight: '5px' }} />
        </Button>
      </div>
    </div>
  );
}

export default ObjectView;
