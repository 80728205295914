import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { AuthContext, AuthProvider } from '../../../contexts/auth';
import {
  CssBaseline,
  Divider,
  TextField,
  Grid,
  Typography,
  Container,
  Button,
  Modal,
  Box,
  ButtonGroup,
  Hidden,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import PaddedLayout from '../UI/PaddedLayout';
import { useCollection } from 'react-firebase-hooks/firestore';
import { auth, firestore, functions } from '../../../services/firebase';

const getPhotoFromGoogleAlbum = functions.httpsCallable('getPhotoFromGoogleAlbum');

export default function UploadPhotos({ close }) {
  //  close is a callback when done

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { currentUser, userInfo, updateUserInfo } = useContext(AuthContext);

  const GETPHOTOURL = 'https://us-central1-dialography-29b8b.cloudfunctions.net/getPhotoFromGoogleAlbum';
  // const GETPHOTOURL = 'http://localhost:5001/dialography-29b8b/us-central1/getPhotoFromGoogleAlbum';

  const [photoURLForm, setPhotoURLForm] = useState('');
  const [albumName, setAlbumName] = useState('');

  const handleClose = () => {
    if (close) {
      close();
    } else {
      history.push('/albumslist');
    }
  };
  const handleGetGooglePhotoFormSubmit = async () => {
    let res = await getPhotoFromGoogleAlbum({
      firestore: 'yes',
      albumURL: photoURLForm,
      userId: currentUser.uid,
      albumName: albumName,
    });
    handleClose();
  };

  // END GETTING PHOTOS FROM FIREBASE

  return (
    <Grid item container xs={12} spacing={5} direction="row" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h2">Upload Photos</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">Step 1</Typography>
        <Typography>Create a shared album in google photos, and paste the URL here. </Typography>
        <Typography>Testing, use: https://photos.app.goo.gl/jcfwWX2cKW7WJpLp8</Typography>
        <TextField
          style={{ width: '100%' }}
          label="Paste Google Photos Album URL here"
          variant="outlined"
          name="name"
          id="name"
          autoComplete="google-photo-URL"
          value={photoURLForm}
          margin="none"
          onChange={e => setPhotoURLForm(e.target.value)}
        />
        <Divider />
        <Typography variant="h4">Step 2</Typography>
        <Typography>Enter a name for this album </Typography>

        <TextField
          style={{ width: '100%' }}
          label="Album Name"
          variant="outlined"
          name="name"
          id="name"
          autoComplete="google-photo-album-name"
          value={albumName}
          margin="none"
          onChange={e => setAlbumName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          disabled={!photoURLForm}
          variant="contained"
          size="large"
          disableElevation
          onClick={async () => {
            await handleGetGooglePhotoFormSubmit();
            // Props.close();
          }}
        >
          Submit
        </Button>
        <Button
          fullWidth
          variant="outlined"
          color="secondary"
          disableElevation
          onClick={async () => {
            await handleClose();
            // Props.close();
          }}
        >
          Close
        </Button>
      </Grid>
    </Grid>
  );
}
