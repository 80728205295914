import { useContext } from 'react';
import { ActivityContext } from '../../components/ActivityProvider';

export default function useActivityContext() {
  const context = useContext(ActivityContext);
  if (!context) {
    throw new Error('useActivityContext must be used within a ActivityProvider');
  }
  return context;
}
