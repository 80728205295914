import React, { useState, useEffect, createContext } from 'react';
import { auth, firestore } from '../services/firebase';
import LinearProgress from '@mui/material/LinearProgress';

// PROOF THAT I AM NOT A PSYCHO WITH THIS LOCALSTORAGE SHEIT:
// https://ogzhanolguncu.com/blog/handling-loggedin-user-firebase-react-app

export const AuthContext = createContext();
export const AuthProvider = ({ children }): any => {
  const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('authUser')) || null);
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')) || {});

  // processed interfaces to the userInfo object
  const groupList = Object.keys(userInfo.groupInfo ?? {}).map(gid => ({ id: gid, ...userInfo.groupInfo[gid] }));

  const [pending, setPending] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      user => {
        if (user) {
          localStorage.setItem('authUser', JSON.stringify(user));

          user.getIdTokenResult().then(idTokenResult => {
            user.claims = idTokenResult.claims;
            setCurrentUser(previousCurrentUser => {
              return user;
            });
          });
        } else {
          localStorage.removeItem('authUser');
          localStorage.removeItem('userInfo');

          setCurrentUser(null);
          setPending(false);
        }
      },
      error => {
        localStorage.removeItem('authUser');
        localStorage.removeItem('userInfo');
        setCurrentUser(null);
        setPending(false);
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = firestore
        .collection('users')
        .doc(currentUser.uid)
        .onSnapshot(querySnapshot => {
          let userData = querySnapshot.data();
          setUserInfo({ ...userData, id: querySnapshot.id });
          setPending(false);
          localStorage.setItem('userInfo', JSON.stringify({ ...userData, id: querySnapshot.id }));
        });
      return unsubscribe;
    }
  }, [currentUser]);

  // EDIT USER INFO
  const updateUserInfo = newInfo => {
    firestore
      .collection('users')
      .doc(currentUser.uid)
      .update(newInfo);
  };

  // return <div>{JSON.stringify(auth.currentUser)}</div>
  // if (pending) {
  //   return <div>loading</div>;
  // }
  return (
    <AuthContext.Provider value={{ pending, currentUser, userInfo, updateUserInfo, groupList }}>
      {children}
    </AuthContext.Provider>
  );
};
