import { Button, FormControl, FormControlLabel, FormGroup, Grid, Switch, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../contexts/auth';
import { functions } from '../../../services/firebase';

const attachedAlbumToGroup = functions.httpsCallable('attachedAlbumToGroup');

export default function AttachAlbumToGroup(Props) {
  const album = Props.album;

  const { userInfo } = useContext(AuthContext);

  let userGroups = userInfo.groupInfo ?? {};
  let userGroupList = Object.keys(userGroups).map(z => ({ id: z, ...userGroups[z] }));

  const [editGroups, set_editGroups] = useState(Object.keys(album.groups ?? {}));
  const [hasEdit, set_hasEdit] = useState(false);

  const handleSubmit = async () => {
    for (let i = 0; i < editGroups.length; i++) {
      await attachedAlbumToGroup({ albumId: album.id, groupId: editGroups[i] });
    }
    if (Props.close) {
      Props.close();
    }
  };

  return (
    <Grid item container xs={12} spacing={5} direction="row" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h6">Add Album "{album.name}" To Groups</Typography>
      </Grid>

      <Grid item>
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            {userGroupList.map(g => (
              <FormControlLabel
                key={g.id}
                control={
                  <Switch
                    checked={editGroups.includes(g.id)}
                    onChange={() => {
                      if (editGroups.includes(g.id)) {
                        set_editGroups(z => z.filter(x => x != g.id));
                      } else {
                        set_editGroups(z => [...z, g.id]);
                      }
                      set_hasEdit(true);
                    }}
                    name={g.name}
                  />
                }
                label={g.name}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          disabled={hasEdit == false}
          disableElevation
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
}
