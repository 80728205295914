import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import { firestore, functions } from '../../../services/firebase';
import { AuthContext } from '../../../contexts/auth';

const checkDialogAccess = functions.httpsCallable('checkDialogAccess');

export const DialogAccessContext = createContext();

export function DialogAccessProvider({ children }) {
  const { currentUser, userInfo } = useContext(AuthContext);
  let uid = currentUser.uid;

  // this is the first varible checked with cloud function
  // does this user have access?
  // note, if they don't, they cant read the dialog document
  const [hasDialogAccess, set_hasDialogAccess] = useState(null);

  const { dialogId } = useParams();

  const checkAccess = async () => {
    let res = await checkDialogAccess({ dialogId });
    set_hasDialogAccess(res.data.access);
  };

  useEffect(() => {
    checkAccess();
  }, []);

  if (hasDialogAccess == null) {
    return (
      <DialogAccessContext.Provider>
        <div>checking access....</div>
      </DialogAccessContext.Provider>
    );
  }
  if (hasDialogAccess) {
    return <DialogAccessContext.Provider>{children}</DialogAccessContext.Provider>;
  } else {
    setTimeout(() => {
      window.location.reload();
    }, 5000);
    return (
      <DialogAccessContext.Provider>
        <div>Waiting to be let in by the users there</div>
      </DialogAccessContext.Provider>
    );
  }
}
