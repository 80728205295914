import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { AuthContext } from '../../../contexts/auth';
import { functions, storage } from '../../../services/firebase';
const createAlbum = functions.httpsCallable('createAlbum');

const useStyles = makeStyles(theme => ({}));

export default function UploadPhotos({ close }) {
  //  close is a callback when done

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const [albumName, setAlbumName] = useState('');
  const history = useHistory();

  const { currentUser, userInfo, updateUserInfo } = useContext(AuthContext);
  let uid = currentUser.uid;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const handleClose = () => {
    if (close) {
      close();
    } else {
      history.push('/albumslist');
    }
  };

  const uploadImagesToStorage = async imageList => {
    // takes images an uploads to storage
    let processedImages = [];
    for (var index = 0; index < imageList.length; index++) {
      let image = imageList[index];
      let imageInfo = { index: index, id: uuidv4() };
      imageInfo['path'] = `/images/${uid}/${imageInfo.id}`;
      // For each image, add to storage, and then get
      // first upload it....
      let uploadRef = storage.ref(imageInfo.path);
      let uploadTask = await uploadRef.put(image['file']);
      imageInfo['url'] = await uploadRef.getDownloadURL();
      //initiates the firebase side uploading
      // uploadTask.on(
      //   'state_changed',
      //   snapShot => {
      //     //takes a snap shot of the process as it is happening
      //     console.log(snapShot);
      //   },
      //   err => {
      //     //catches the errors
      //     console.log('UPLOAD ERROR');
      //     console.log(err);
      //   },
      //   () => {}
      // );
      processedImages.push(imageInfo);
    }
    return processedImages;
  };
  const handleUpload = async imageList => {
    let processedImages = await uploadImagesToStorage(imageList);
    // for (var i = 0; i < processedImages.length; i++) {
    //   processedImages[i]['url'] = await storage
    //     .ref(processedImages[i].path)
    //     .getDownloadURL()
    //     .then(downloadURL => {
    //       // console.log('File available at', downloadURL);
    //       return downloadURL;
    //     });
    // }

    console.log('processedImages[0] ' + JSON.stringify(processedImages[0]));
    let albumId = await createAlbum({ albumName, processedImages });
    handleClose();
  };

  return (
    <Grid item container xs={12} spacing={5} direction="column" alignItems="center">
      <Grid item>
        <Typography variant="h2">Upload Photos</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h4">Step 1</Typography>
        <Typography>Enter a name for this album </Typography>

        <TextField
          label="Album Name"
          variant="outlined"
          name="name"
          id="name"
          autoComplete="google-photo-album-name"
          value={albumName}
          margin="none"
          onChange={e => setAlbumName(e.target.value)}
        />
      </Grid>
      <Grid item>
        <Typography variant="h4">Step 2</Typography>
        <Typography>Upload Images</Typography>
        <ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
          {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
            // write your building UI
            <div className="upload__image-wrapper">
              <button style={isDragging ? { color: 'red' } : undefined} onClick={onImageUpload} {...dragProps}>
                Click or Drop here
              </button>
              &nbsp;
              <button onClick={onImageRemoveAll}>Remove all images</button>
              {imageList.map((image, index) => (
                <div key={index} className="image-item">
                  <img src={image['data_url']} alt="" width="100" />
                  <div className="image-item__btn-wrapper">
                    <button onClick={() => onImageUpdate(index)}>Update</button>
                    <button onClick={() => onImageRemove(index)}>Remove</button>
                    {/* <div>{JSON.stringify(imageList.map(x => x.file))}</div> */}
                  </div>
                </div>
              ))}
            </div>
          )}
        </ImageUploading>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            await handleUpload(images);
          }}
        >
          Upload
        </Button>
      </Grid>
    </Grid>
  );
}
