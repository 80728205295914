import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';

export default function GroupInviteForm({ value, action, close }) {
  // value holds a list of current users invited
  // take this from the group.userInfo object

  const [invites, set_invites] = useState(value.map((x, i) => ({ ...x, index: i })));

  const handleSubmit = () => {
    // alert(JSON.stringify(invites));
    action(invites);
    if (close) {
      close();
    }
  };

  return (
    <Grid container style={{ width: '100%', margin: 0 }} spacing={5} direction="row" alignItems="center">
      <Grid item container xs={12} justifyContent="center">
        <Typography variant="h4">Invite People To Your Circle</Typography>
      </Grid>

      <Grid item container xs={12} justifyContent="center">
        {invites.map(x => (
          <Grid item container spacing={3} xs={12} key={x.index} style={{ margin: 2, border: '1px dashed lightblue' }}>
            <Grid item xs={6}>
              <TextField
                style={{ width: '100%' }}
                label="Email"
                variant="outlined"
                required
                id={'email_' + x.index}
                name={'email_' + x.index}
                autoComplete={null}
                value={x.email}
                onChange={e => {
                  const eTarget = e.target.value;
                  set_invites(currentUsers =>
                    currentUsers.map(z => (z.index == x.index ? { ...z, email: eTarget } : z))
                  );
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{ width: '100%' }}
                label="Name"
                variant="outlined"
                required
                id={'name_' + x.index}
                name={'name_' + x.index}
                autoComplete={null}
                value={x.name}
                onChange={e => {
                  const eTarget = e.target.value;

                  set_invites(currentUsers =>
                    currentUsers.map(z => (z.index == x.index ? { ...z, name: eTarget } : z))
                  );
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Button
                color="secondary"
                variant="outlined"
                style={{ height: '100%' }}
                onClick={() => {
                  set_invites(z => z.filter(y => y.index != x.index));
                }}
              >
                Remove
              </Button>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item container xs={12} justifyContent="center">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            set_invites(x => [...x, { index: Math.max(0, ...x.map(z => z.index)) + 1 }]);
          }}
        >
          {invites.length > 0 ? 'Add Another' : 'Add Invite'}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth variant="contained" color="primary" size="large" onClick={handleSubmit}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}
