import useParticipants from '../../hooks/useParticipants/useParticipants';
import React, { useContext, useState, useEffect, useRef } from 'react';

import { AuthContext } from '../../../contexts/auth';

function useParticipantInfo() {
  const participantsFull = useParticipants();
  const participantIds = participantsFull.map(x => x['identity']).map(x => x.split(':::')[1]);

  const { currentUser, userInfo, updateUserInfo } = useContext(AuthContext);
  const participantInfo = [
    { id: currentUser.uid, name: userInfo.name },
    ...participantIds.map(x => ({ id: x, name: x })),
  ];
  return { participantInfo };
}

export default useParticipantInfo;
