import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
// Added by Jay:
import { AuthContext, AuthProvider } from './contexts/auth';
import AccountPage from './Main/components/Account/AccountScreen';
import Onboarding from './Main/components/Account/OnboardingScreen';
import Album from './Main/components/Albums/Album';
import ManageAlbumsScreen from './Main/components/Albums/ManageAlbumsScreen';
import BetaTestApplication from './Main/components/CreateAccount/BetaTestApplication';
import CreateAccount from './Main/components/CreateAccount/CreateAccount';
import NewDialog from './Main/components/Dialog/NewDialog';

import Features from './Main/components/Features/Features';
import GroupDetailScreen from './Main/components/Groups/GroupScreen';
import Home from './Main/components/Home/Home';
import Login from './Main/components/Login/Login';
import Logout from './Main/components/Logout/Logout';
import Main from './Main/components/Main/Main';
import Chatbot from './Main/components/Testing/Chatbot';
import VideoList from './Main/components/Videos/VideoList';
import WhyDialography from './Main/components/WhyDialography/WhyDialography';
import AppStateProvider, { useAppState } from './state';
import theme from './theme';
import './types';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import App from './VideoChat/App';
import { ActivityProvider } from './VideoChat/components/ActivityProvider';
import { ChatProvider } from './VideoChat/components/ChatProvider';
import { DialogProvider } from './VideoChat/components/DialogProvider';
import { DialogAccessProvider } from './VideoChat/components/DialogAccessProvider';
import ErrorDialog from './VideoChat/components/ErrorDialog/ErrorDialog';
import { EventProvider } from './VideoChat/components/EventProvider';
import PrivateRoute from './VideoChat/components/PrivateRoute/PrivateRoute';
import UnsupportedBrowserWarning from './VideoChat/components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { VideoProvider } from './VideoChat/components/VideoProvider';

const Testing = () => {
  const { currentUser, userInfo } = useContext(AuthContext);

  return (
    <div>
      debug <br />
      <h3>currentUser</h3>
      <pre>{JSON.stringify(currentUser, false, 2)}</pre>
      <br />
      <h3>userInfo</h3>
      <pre>{JSON.stringify(userInfo, false, 2)}</pre>
      <br />
    </div>
  );
};

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <DialogAccessProvider>
        <DialogProvider>
          <ActivityProvider>
            <EventProvider>
              <ChatProvider>
                <App />
              </ChatProvider>
            </EventProvider>
          </ActivityProvider>
        </DialogProvider>
      </DialogAccessProvider>
    </VideoProvider>
  );
};

ReactDOM.render(
  <AuthProvider>
    <MuiThemeProvider theme={theme}>
      <Router>
        <AppStateProvider>
          <Switch>
            <Route exact path="/test">
              <Testing />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/logout">
              <Logout />
            </Route>

            <Route exact path="/signup">
              <CreateAccount />
            </Route>

            <PrivateRoute exact path="/onboarding">
              <Onboarding />
            </PrivateRoute>

            <Route exact path="/join_beta">
              <BetaTestApplication />
            </Route>
            <Route exact path="/why_dialography">
              <WhyDialography />
            </Route>
            <Route exact path="/features">
              <Features />
            </Route>
            <Route exact path="/new_dialog/:groupId?">
              {/* <UnsupportedBrowserWarning>
                <Main />
              </UnsupportedBrowserWarning> */}
              <NewDialog />
            </Route>

            <PrivateRoute exact path="/account">
              <AccountPage />
            </PrivateRoute>
            <PrivateRoute exact path="/group/:id">
              <GroupDetailScreen />
            </PrivateRoute>
            <PrivateRoute exact path="/managealbums">
              <ManageAlbumsScreen />
            </PrivateRoute>

            <PrivateRoute exact path="/album/:AlbumID">
              <Album />
            </PrivateRoute>

            <PrivateRoute exact path="/gallery">
              <VideoList />
            </PrivateRoute>
            {/* <PrivateRoute exact path="/transcribe">
              <Transcription />
            </PrivateRoute> */}
            <PrivateRoute exact path="/chatbot">
              <Chatbot />
            </PrivateRoute>

            <PrivateRoute exact path="/videoapp">
              <UnsupportedBrowserWarning>
                <VideoApp />
              </UnsupportedBrowserWarning>
            </PrivateRoute>

            <PrivateRoute exact path="/dialog/:dialogId">
              <UnsupportedBrowserWarning>
                <VideoApp />
              </UnsupportedBrowserWarning>
            </PrivateRoute>

            <PrivateRoute path="/room/:URLRoomName">
              <UnsupportedBrowserWarning>
                <VideoApp />
              </UnsupportedBrowserWarning>
            </PrivateRoute>
            {/* <Route path="/login">
              <LoginPage />
            </Route> */}
            <Route path="/">
              <Home />
            </Route>
            <Redirect to="/" />
          </Switch>
        </AppStateProvider>
      </Router>
    </MuiThemeProvider>
  </AuthProvider>,
  document.getElementById('root')
);
