import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import { PhotoContext } from '../../../contexts/photos';
import ActionButton from '../UI/ActionButton.js';
import ModalButton from '../UI/ModalButton.js';
import EditImage from './EditImage.js';

export default function Image(Props) {
  const { imageID } = Props;

  const { photoList } = useContext(PhotoContext);

  const image = photoList.filter(x => x.id == imageID)[0];

  if (!image) {
    return <div>loading</div>;
  }
  return (
    <Grid item container xs={12} md={8} direction="column">
      {/* <ModalButton text="Edit Image" title="Edit Image">
          <EditImage image={image} />
        </ModalButton> */}

      <Grid item style={{ height: '60vh' }}>
        <img src={image.url} style={{ height: '100%' }} />
      </Grid>
    </Grid>
  );
}
