import React, { useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  InputAdornment,
  IconButton,
  TextField,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { LockOutlined, PersonOutline, Visibility, VisibilityOff } from '@material-ui/icons';
import { auth } from '../../../services/firebase';
import { useLocation } from 'react-router';
const useStyles = makeStyles(theme => ({
  setupHeader: {
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  box: {
    margin: 'auto',
    marginTop: '30px',
    color: theme.palette.primary.main,
    marginBotton: '30px',
    maxWidth: '470px',
  },
  paper: {
    padding: '15px',
    paddingTop: '0px',
  },
  createButton: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    color: 'white',
    marginBotton: '10px',
    marginTop: '10px',
  },
  createButtonDisabled: {
    width: '100%',
    opacity: '05',
    color: theme.palette.primary.main,
    marginBotton: '10px',
    marginTop: '10px',
  },
  backdropProgress: {
    zIndex: '300',
    width: '100%',
    position: 'absolute',
    top: '0',
  },
  backdrop: {
    zIndex: '300',
  },
  alert: {
    marginBottom: '10px',
  },
}));
const Login = () => {
  const classes = useStyles();
  const handleLogout = async () => {
    alert('logging out!');

    await auth.signOut();

    window.location.assign('/login');

    return;
  };
  return (
    <Box width="80%" className={classes.box}>
      <Paper className={classes.paper}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button variant="contained" onClick={handleLogout} type="submit">
            Logout
          </Button>
        </Grid>
      </Paper>
    </Box>
  );
};
export default Login;
