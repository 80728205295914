import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';

import { AuthContext, AuthProvider } from '../../../contexts/auth';
import {
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Container,
  Button,
  Modal,
  Box,
  ButtonGroup,
  Hidden,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useCollection } from 'react-firebase-hooks/firestore';
import { auth, firestore } from '../../../services/firebase';

import ModalButton from '../UI/ModalButton.js';

const useStyles = makeStyles(theme => ({
  editModal: {
    width: '80%',
    marginLeft: '10%',
    marginTop: '10%',
    padding: 20,
    bgcolor: 'white',
    boxShadow: 1,
  },
  newDialog: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    margin: 'auto',
    width: '100%',
    maxWidth: '1440px',
    paddingBottom: '150px',
  },
  leftPane: {
    paddingLeft: '10px',
    paddingRight: '10px',
    [theme.breakpoints.between('md', 'xl')]: {
      paddingTop: '100px',
      paddingLeft: '100px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: '50px',
    },
  },
  joinDialogMediumText: {
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '24px',
      lineHeight: '28px',
      paddingTop: '30px',
      paddingBottom: '30px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '16px',
      lineHeight: '20px',
      paddingTop: '15px',
      paddingBottom: '15px',
    },

    color: '#000000',
    paddingTop: '30px',
    paddingBottom: '30px',
  },
  neutral400: {
    color: '#737581',
  },
  smallTextBold: {
    fontWeight: '600',
    textAlign: 'center',

    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '18px',
      lineHeight: '21px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '16px',
      lineHeight: '19px',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
  },
  largeTextBold: {
    fontWeight: '600',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '42px',
      lineHeight: '49px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '32px',
      lineHeight: '37px',
    },
    textAlign: 'center',

    color: '#000000',
  },
  newDialogHero: {
    backgroundRepeat: 'no-repeat',
    background: 'url(newdialog_hero.jpg)',
    width: '600px',
    height: '400px',
    marginTop: '100px',
  },
  inputbox: {
    width: '93%',
    lineHeight: '42px',
    borderRadius: '0px',
    marginTop: '0px !important',

    '& .MuiOutlinedInput-input': {
      color: '#2D82B7',
      textAlign: 'left',
    },
    '& .MuiInputLabel-root': {
      color: '#2D82B7',
      textAlign: 'left',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D82B7',
      textAlign: 'left',
      borderRadius: '0px',
    },
    '&:hover .MuiOutlinedInput-input': {
      color: '#2D82B7',
    },
    '&:hover .MuiInputLabel-root': {
      color: '#2D82B7',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D82B7',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D82B7',
    },
    [theme.breakpoints.between('md', 'xl')]: {
      margin: '0px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '5px',
    },
  },
  startNewDialogButton: {
    color: '#FFFFFF',
    backgroundColor: '#2D82B7',
    fontSize: '20px',
    fontWeight: 'normal',
    lineHeight: '42px',
    borderRadius: '0px',
  },
  joinDialogButton: {
    color: '#FFFFFF',
    backgroundColor: '#2D82B7',
    fontWeight: 'normal',
    borderRadius: '0px',
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '20px',
      fontWeight: 'normal',
      lineHeight: '38px',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '18px',
      fontWeight: 'normal',
      lineHeight: '38px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '38px',
    },
  },
}));

export default function EditAlbum(Props) {
  const album = Props.album;

  const [nameEdit, setNameEdit] = useState(Props.album.name);

  const handleSubmit = () => {
    firestore
      .collection('albums')
      .doc(album.id)
      .update({ name: nameEdit });
    //
    Props.albumPhotos.forEach(x => {
      firestore
        .collection('photos')
        .doc(x.id)
        .update({ albumName: nameEdit });
    });
    //
    Props.close();
  };
  // END GETTING PHOTOS FROM FIREBASE

  return (
    <Grid item container xs={12} spacing={5} direction="column" alignItems="center">
      <Grid item>
        <Typography variant="h6">Edit Album Name</Typography>
      </Grid>

      <Grid item>
        <TextField
          label="Name"
          variant="outlined"
          name="name"
          id="name"
          autoComplete="album-name"
          value={nameEdit}
          margin="none"
          onChange={e => setNameEdit(e.target.value)}
        />
      </Grid>
      <Grid item>
        <Button fullWidth variant="contained" size="large" disableElevation onClick={handleSubmit}>
          Save
        </Button>
      </Grid>
      <Grid item>
        <Button fullWidth variant="contained" size="large" disableElevation onClick={() => Props.close()}>
          Close
        </Button>
      </Grid>
    </Grid>
  );
}
