import { useContext } from 'react';
import { DialogContext } from '../../components/DialogProvider';

export default function useDialogContext() {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('DialogContext must be used within a DialogProvider');
  }
  return context;
}
