import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext } from 'react';
import useActivityContext from '../../hooks/useActivityContext/useActivityContext';
import ActivityHandler from '../ActivityHandler';
import MainParticipant from '../MainParticipant/MainParticipant';
import ParticipantList from '../ParticipantList/ParticipantList';
import { Grid, Box } from '@mui/material';
import ActivityList from '../ActivityHandler/ActivityList';

const useStyles = makeStyles((theme: Theme) => {
  const totalMobileSidebarHeight = `${theme.sidebarMobileHeight +
    theme.sidebarMobilePadding * 2 +
    theme.participantBorderWidth}px`;
  return {
    container_hide_tray: {
      position: 'relative',
      // background: '#A1DAD2',
      gridArea: 'main',
      display: 'grid',
      width: '100%',
      gridTemplateAreas: `
                    'tray canvas '
                `,
      gridTemplateColumns: '0px auto',
      gridTemplateRows: 'calc(100vh - 235px) ',
      marginRight: `calc(${theme.sidebarWidth}px - 3em)`,
    },
    container_show_tray: {
      position: 'relative',
      // background: '#A1DAD2',
      gridArea: 'main',
      display: 'grid',
      width: '100%',
      gridTemplateAreas: `
                    'tray canvas '
                `,
      gridTemplateColumns: '200px auto',
      gridTemplateRows: 'calc(100vh - 235px) ',
    },
    tray_hide: {
      gridArea: 'tray',
      width: '200px',
      height: '100%',
      display: 'none',
      visibility: 'hidden',
      position: 'absolute',
      left: 0,
      backgroundColor: '#FFFFFF',
    },
    tray_show: {
      gridArea: 'tray',
      width: '200px',
      height: '100%',
      display: 'block',
      visibility: 'visible',
      position: 'absolute',
      left: 0,
      backgroundColor: '#FFFFFF',
    },
    activity_canvas: {
      position: 'relative',
      display: 'flex',
      alignItems: 'top',
      width: '100%',
      // '`calc(100vw - ${theme.sidebarWidth}px - 3em)`,
      height: '100%',
    },
  };
});

export default function Room(Props) {
  const classes = useStyles();
  const {
    showActivity,
    setShowActivity,
    participants,
    activities,
    currentActivity,
    setCurrentActivity,
  } = useActivityContext();

  return (
    <div style={{ height: Props.size.height, width: Props.size.width }}>
      {currentActivity && currentActivity.name && Object.keys(activities).includes(currentActivity.name) ? (
        <div className={clsx(classes.activity_canvas)}>
          {React.createElement(activities[currentActivity.name].component, {
            key: currentActivity.key,
            participants: participants,
            size: Props.size,
            // isLocalEvent: currentActivity.isLocalEvent,
            // payload: currentActivity.payload,
          })}
        </div>
      ) : (
        <ActivityList height={Props.size.height} width={Props.size.width} />
        // <MainParticipant />
      )}
    </div>
  );
}
