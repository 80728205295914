// @flow

import React, { Component } from 'react';
import type { SurveyQuestion, AutocompleteRequestFunction } from '../../material-survey-format.js.flow';

import SliderQuestion from '../SliderQuestion';
import RadiogroupQuestion from '../RadiogroupQuestion';
import RatingQuestion from '../RatingQuestion';
import DropdownQuestion from '../DropdownQuestion';
import ChoiceRankerQuestion from '../ChoiceRankerQuestion';
import BooleanQuestion from '../BooleanQuestion';
import TextQuestion from '../TextQuestion';
import MultiTextQuestion from '../MultiTextQuestion';
import USRegionQuestion from '../USRegionQuestion';
import CheckboxQuestion from '../CheckboxQuestion';
import APIAutocompleteQuestion from '../APIAutocompleteQuestion';
import DynamicMatrixQuestion from '../DynamicMatrixQuestion';
import FileQuestion from '../FileQuestion';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';

//CUSTOM
import ListSelectQuestion from '../ListSelectQuestion';

import ButtonGroupQuestion from '../ButtonGroupQuestion';
import TypedQuestion from '../TypedQuestion';
import DisplayImage from '../DisplayImage';
import DisplayText from '../DisplayText';

export type Props = {
  question: SurveyQuestion,
  answerMap: any,
  onChangeAnswer: Function,
  autocompleteRequest?: AutocompleteRequestFunction,
  onFileUpload?: File => Promise<string>,
};

const Red = styled.div`
  color: red;
`;

class SurveyQuestionComponent extends Component<*, *> {
  shouldComponentUpdate = (nextProps: Props) => {
    return !isEqual(nextProps.question, this.props.question);
  };
  onChangeAnswer = (...args: any) => this.props.onChangeAnswer(...args);
  render = () => {
    const {
      question,
      onChangeAnswer,
      answerMap,
      autocompleteRequest,
      onFileUpload,
      userData,
      registerAction,
      registerActionHandler,
    } = this.props;

    let actionManager = registerAction ? { registerAction, registerActionHandler } : null;

    let customTagDict = {
      buttongroup: ButtonGroupQuestion,
      // "videomessage": VideoMessageQuestion,
      // "audiomessage": AudioMessageQuestion,
      // "photo": PhotoQuestion,
      typed: TypedQuestion,
      // "artifact": ArtifactDisplayQuestion,
      // "contacts": ContactsQuestion,
      // "artifactlist": ArtifactListQuestion,
      // "albumlist": AlbumListQuestion,
      listselect: ListSelectQuestion,
      // "albumplayer": AlbumDisplayQuestion
    };

    const self = this;
    if (Object.keys(customTagDict).includes(question.type)) {
      const customTagName = customTagDict[question.type];

      return React.createElement(customTagName, {
        question: question,
        answerMap: answerMap,
        userData: userData,
        onChangeAnswer: self.onChangeAnswer,
        actionManager,
      });
    }

    switch (question.type) {
      case 'displaytext': {
        return <DisplayText question={question} onChangeAnswer={this.onChangeAnswer} actionManager />;
      }
      case 'displayimage': {
        return <DisplayImage question={question} onChangeAnswer={this.onChangeAnswer} actionManager />;
      }
      case 'slider': {
        return <SliderQuestion question={question} onChangeAnswer={this.onChangeAnswer} actionManager />;
      }
      case 'radiogroup': {
        return <RadiogroupQuestion question={question} onChangeAnswer={this.onChangeAnswer} actionManager />;
      }

      case 'multipletext': {
        return <MultiTextQuestion question={question} onChangeAnswer={this.onChangeAnswer} actionManager />;
      }
      case 'multiline-text':
      case 'text': {
        return <TextQuestion question={question} onChangeAnswer={this.onChangeAnswer} actionManager />;
      }
      case 'dropdown':
      case 'multiple-dropdown': {
        return <DropdownQuestion question={question} onChangeAnswer={this.onChangeAnswer} actionManager />;
      }
      case 'checkbox': {
        return <CheckboxQuestion question={question} onChangeAnswer={this.onChangeAnswer} actionManager />;
      }
      case 'us-region':
      case 'multiple-us-region':
      case 'us-state':
      case 'multiple-us-state': {
        return <USRegionQuestion question={question} onChangeAnswer={this.onChangeAnswer} actionManager />;
      }
      case 'boolean': {
        return <BooleanQuestion question={question} onChangeAnswer={this.onChangeAnswer} actionManager />;
      }
      case 'choiceranker':
      case 'choice-ranker': {
        return <ChoiceRankerQuestion question={question} onChangeAnswer={this.onChangeAnswer} actionManager />;
      }
      case 'rating': {
        return <RatingQuestion question={question} onChangeAnswer={this.onChangeAnswer} actionManager />;
      }
      case 'autocomplete': {
        if (!autocompleteRequest)
          throw new Error('You must supply autocompleteRequest method to use APIAutocompleteQuestions');
        return (
          <APIAutocompleteQuestion
            question={question}
            onChangeAnswer={this.onChangeAnswer}
            autocompleteRequest={autocompleteRequest}
            actionManager
          />
        );
      }
      case 'matrixdynamic': {
        return (
          <DynamicMatrixQuestion
            question={question}
            autocompleteRequest={autocompleteRequest}
            onChangeAnswer={this.onChangeAnswer}
            actionManager
          />
        );
      }
      case 'file': {
        if (!onFileUpload) return <Red>File Question Type requires an upload handler. Specify onFileUpload.</Red>;
        return (
          <FileQuestion
            question={question}
            onFileUpload={onFileUpload}
            onChangeAnswer={this.onChangeAnswer}
            actionManager
          />
        );
      }
      default: {
        throw new Error(`Invalid Question Type: "${question.type}" in "${JSON.stringify(question, null, '  ')}"`);
      }
    }
  };
}

export default SurveyQuestionComponent;
