import React, { useState } from 'react';
import useQuestionAnswer from '../../hooks/use-question-answer';
import Radio from '@material-ui/core/Radio';
import QuestionContainer from '../QuestionContainer';
import Button from '@material-ui/core/Button';
import { List, ListItem, ListItemText } from '@material-ui/core';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import styled from 'styled-components';
import QuestionText from '../QuestionText';
import TextField from '@material-ui/core/TextField';

const RadioItem = styled(Button)`
  && {
    display: flex;
    margin-top: 1px;
    padding: 0;
    padding-right: 20px;
    text-transform: none;
  }
`;

const RadioItemNoRipple = styled.div`
  && {
    display: flex;
    margin-top: 1px;
    padding: 0;
    padding-right: 20px;
    align-items: center;
    input {
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

export default function ListSelectQuestion({
  question,
  onChangeAnswer,
  actionManager,
}: {
  question: any,
  onChangeAnswer: Function,
}) {
  const [{ answer, error }, changeAnswer] = useQuestionAnswer(
    question,
    onChangeAnswer,
    question.defaultAnswer || undefined,
    actionManager
  );
  const [otherText, changeOtherText] = useState(
    question.choices.some(c => c === answer || (c || {}).value === answer) ? '' : answer
  );
  const choices = question.choices.map(c => (typeof c === 'string' ? { text: c, value: c } : c));

  return (
    <QuestionContainer question={question} answered={answer !== undefined} error={error}>
      <List>
        {choices.map(x => (
          <ListItem
            key={x.value}
            elevation={4}
            divider={true}
            selected={answer === x.value}
            onClick={() => {
              changeAnswer(x.value);
            }}
          >
            <ListItemText primary={x.text ? x.text : x.value} secondary={x.subtext} />
          </ListItem>
        ))}
      </List>
    </QuestionContainer>
  );
}
