import { Grid } from '@material-ui/core';
import React from 'react';

export default function PaddedLayout(Props) {
  return (
    <Grid container>
      {/* padding */}
      <Grid item md={2} xs={false}></Grid>

      {/* content */}
      <Grid item md={8} xs={12}>
        {Props.children}
      </Grid>
      {/* padding */}
      <Grid item md={2} xs={false}></Grid>
    </Grid>
  );
}
