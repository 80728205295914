import { Card, CardActions, CardContent, CardHeader, Chip, Grid, Paper } from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import { AuthContext } from '../../../contexts/auth';
import ModalButton from '../UI/ModalButton.js';
import AlbumCard from './AlbumCard.js';

export default function AlbumList({ albumList }) {
  const location = useLocation();
  const history = useHistory();
  const { currentUser, userInfo, updateUserInfo } = useContext(AuthContext);

  let userGroupInfo = userInfo.groupInfo ?? {};
  let userGroupList = Object.keys(userGroupInfo).map(id => ({ id, ...userGroupInfo[id] }));

  return (
    <Grid container item xs={12} style={{ width: '100%' }}>
      {albumList.map(a => (
        <AlbumCard key={a.id} album={a} />
      ))}
    </Grid>
  );
}
