import React, { createContext, useContext, useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firestore, storage } from '../services/firebase';
import { AuthContext } from './auth';

export const PhotoContext = createContext();

export function PhotoProvider({ children }) {
  const { currentUser, userInfo, updateUserInfo } = useContext(AuthContext);
  const [albumPhotosMap, set_albumPhotosMap] = useState({});
  const [photoList, set_photoList] = useState({});

  useEffect(() => {
    if (userInfo) {
      // let unsubscribes = {}

      let allPhotoList = [];
      let albumInfo = userInfo.albumInfo ?? {};
      let groupInfo = userInfo.groupInfo ?? {};
      // albums owned by the user
      let albumIds = Object.keys(albumInfo);

      // also get all albums for groups
      for (var i = 0; i < Object.keys(groupInfo).length; i++) {
        let gid = Object.keys(groupInfo)[i];
        albumIds.push(...Object.keys(groupInfo[gid].albumInfo ?? {}));
      }

      for (var i = 0; i < albumIds.length; i++) {
        let aid = albumIds[i];
        firestore.collection(`albums/${aid}/photos`).onSnapshot(async querySnapshot => {
          let pcData = querySnapshot.docs.map((p, index) => ({ ...p.data(), id: p.id, index: index }));
          for (let j = 0; j < pcData.length; j++) {
            let downloadURL = await getImageURL(pcData[j]);
            // console.log("GOT A DOWNLOAD URL")
            pcData[j]['url'] = downloadURL;
          }
          allPhotoList.push(...pcData);

          // let pcDataWithURL = pcData.map(p => ({ foo: 'bar', url: getImageURL(p), ...p }));
          set_albumPhotosMap(x => ({ ...x, [aid]: pcData }));
        });
        set_photoList(allPhotoList);
        // return unsubscribes;
      }
    }
  }, [userInfo]);

  const getImageURL = async image => {
    // This function gets the download URL
    // if the image is stored with path, that means it in our storage bucket
    // and we need a temp signed URL
    if (image.url) {
      return image.url;
    }
    if (image.path) {
      // this is an internal hosted, need to get download URL
      let url = await storage
        .ref(image.path)
        .getDownloadURL()
        .then(downloadURL => {
          // console.log('File available at', downloadURL);
          return downloadURL;
        });
      // console.log("URL is "+url)
      return url;
    }
  };

  return <PhotoContext.Provider value={{ albumPhotosMap, photoList }}>{children}</PhotoContext.Provider>;
}
