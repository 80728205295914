import React, { createContext, useState, useEffect } from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { LocalDataTrack, RemoteDataTrack } from 'twilio-video';

type EventContextType = {
  event: string[];
};

export const EventContext = createContext<EventContextType>([]);

export function EventProvider({ children }) {
  const { localTracks } = useVideoContext();
  const localDataTrack = localTracks.find(track => track.kind === 'data') as LocalDataTrack;
  const [event, setEvent] = useState<string[]>([]);

  const [lastEvent, setLastEvent] = useState<string[]>({});

  const addEvent = newEvent => {
    let newEventObj = JSON.parse(newEvent);

    setLastEvent(newEventObj);

    if (newEventObj) {
      if (newEventObj['isLocalEvent']) {
        // newEventObj['isLocalEvent'] = false;
        (localDataTrack as LocalDataTrack).send(JSON.stringify({ ...newEventObj, isLocalEvent: false }));
      }
      setEvent(currentEvent => {
        if (!currentEvent.includes(newEvent)) return [...currentEvent, newEvent];
        return currentEvent;
      });
    }
  };
  return <EventContext.Provider value={{ lastEvent, event, addEvent }}>{children}</EventContext.Provider>;
}
