import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import QuestionContainer from '../QuestionContainer';

import Typed from 'react-typed';
import Handlebars from 'handlebars';

import useQuestionAnswer from '../../hooks/use-question-answer';

export default ({ question, onChangeAnswer, answerMap, actionManager }) => {
  const [{ answer, error }, changeAnswer] = useQuestionAnswer(
    question,
    onChangeAnswer,
    ''
    // actionManager - NOTE, dont use action manager for Typed, it acts odd right now
  );
  let myText = question.text;
  // make sure its a list
  let myTextArray = Array.isArray(myText) ? myText : [myText];

  function processText(x, data) {
    if (x.includes('{{')) {
      // alert(this.question.text)
      let template = Handlebars.compile(x);
      return template(data);
    }
    return x;
  }

  myTextArray = myTextArray.map(x => processText(x, answerMap));

  return (
    <QuestionContainer question={question} error={error} answered={answer !== ''}>
      <Typed
        // typedRef={(typed) => { this.typed = typed; }}
        style={{ fontSize: '20px' }}
        // onComplete={()=>{changeAnswer(true)}}
        strings={myTextArray}
        typeSpeed={70}
        showCursor={false}
      />
    </QuestionContainer>
  );
};
