import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import FeatureBox from './FeatureBox';

const useStyles = makeStyles(theme => ({
  features: {
    fontFamily: 'Roboto',
    display: 'grid',
    [theme.breakpoints.between('md', 'xl')]: {
      gridTemplateAreas: `
  		            'hero_text_box hero_animation '
                  	'footer footer'
  		        `,
      gridTemplateColumns: '60% 40%',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      gridTemplateAreas: `
      		  'hero_animation'
              'hero_text_box'
              'footer'
      `,
      gridTemplateColumns: '100%',
      marginTop: '20px',
    },
    width: '100vw',
    height: '100%',
    maxWidth: '1440px',
    margin: 'auto',
  },
  hero_text_box: {
    display: 'grid',
    gridArea: 'hero_text_box',
    backgroundColor: '#E9FFFC',
    [theme.breakpoints.between('md', 'xl')]: {
      gridTemplateAreas: `
			            'hero_text hero_text_bar'
			            `,
      gridTemplateColumns: '92% 8%',
      gridTemplateRows: 'auto',
      height: '390px',
      marginTop: '20px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100vw',
      gridTemplateAreas: `
    					'hero_text_bar'
			            'hero_text'
			            
			            `,
      gridTemplateColumns: '100%',
    },
  },
  hero_text: {
    gridArea: 'hero_text',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '56px',
      lineHeight: '66px',
      paddingLeft: '30px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '34px',
      paddingTop: '20px',
      paddingBottom: '30px',
      lineHeight: '42px',
    },
    fontWeight: '300',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '300',
    margin: 'auto',
    textAlign: 'center',
  },
  hero_text_bar: {
    gridArea: 'hero_text_bar',
    [theme.breakpoints.between('md', 'xl')]: {
      height: '40%',
      width: '1px',
      marginRight: '35px',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: '25px',
      height: '1px',
      width: '50%',
      margin: 'auto',
    },
    backgroundColor: '#000000',
  },
  hero_animation: {
    gridArea: 'hero_animation',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E9FFFC',
    verticalAlign: 'center',
    [theme.breakpoints.between('md', 'xl')]: {
      width: '100%',
      height: 'calc(100% - 85px)',
      marginTop: '20px',
      paddingTop: '65px',
    },
    overflow: 'hidden',
  },
  hero_animation_mobile: {
    gridArea: 'hero_animation',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'middle',
    backgroundColor: '#E9FFFC',
    display: 'flex',
    width: '100vw',
    overflow: 'hidden',
    paddingTop: '25px',
  },
  underline_bold: {
    fontWeight: '700',
    textDecoration: 'underline',
  },
  bold: {
    fontWeight: '900',
  },
  paragraph_break: {
    marginTop: '25px',
  },
}));

export default function Features() {
  const classes = useStyles();

  return (
    <div>
      <Header />
      <div className={classes.features}>
        <Hidden mdUp>
          <div className={classes.hero_animation_mobile}>
            <video autoPlay muted loop style={{ objectFit: 'fill', width: '80%' }}>
              <source src="/dialography_features_video.mp4" type="video/mp4" />
            </video>
          </div>
        </Hidden>
        <div className={classes.hero_text_box}>
          <div className={classes.hero_text}>
            <span className={classes.bold}>Dialography</span> provides <br />
            a dedicated space for <br />
            <span className={classes.underline_bold}>great conversations</span>
          </div>
          <div className={classes.hero_text_bar}> </div>
        </div>
        <Hidden smDown>
          <div className={classes.hero_animation}>
            <video autoPlay muted loop style={{ objectFit: 'fill', width: '90%', margin: 'auto' }}>
              <source src="/dialography_features_video.mp4" type="video/mp4" />
            </video>
          </div>
        </Hidden>
      </div>

      <FeatureBox
        heading="Purposeful intelligently guided conversations and activities"
        feature_image="/features_guided_conversations.png"
        image_width="240px"
        image_height="220px"
        aligned="right"
        text={[
          'Built in collaboration with Anthropologists and Psychologists activities address important topics, including health check-ins,  and complete-the-blank activities.',
          <div className={classes.paragraph_break}></div>,
          "Dialogs also include a shared photo album where users can browse photos together, watch videos and listen to music together in an easy portal, with recommended content catered to the user's interest. ",
          <div className={classes.paragraph_break}></div>,
          'And there are many more Dialog types that users can utilize to foster more connectedness. ',
        ]}
      />
      <FeatureBox
        heading="Synchronized shared canvas for co-creation"
        feature_image="/features_shared_canvas.png"
        image_width="240px"
        image_height="247px"
        aligned="left"
        text={[
          'Dialogs are social activities that promote cognitive health and connectedness. Dialogs occur in real-time utilizing an interactive collaborative canvas.  The canvases are sync’d between users, allowing for shared navigation and exploration in a safe and comfortable environment.',
          <div className={classes.paragraph_break}></div>,
          'Dialography promotes healthy intergenerational dialog through purposeful, intelligently guided conversations and activities between seniors and the ones that care for them.',
        ]}
      />
      <FeatureBox
        heading="Accessibility designed at the applications core"
        feature_image="/features_accessible.png"
        image_width="240px"
        image_height="233px"
        aligned="right"
        text={[
          'Dialography was built with accessibility at its core, making actions and buttons obvious for seniors who weren’t exposed to technology during their younger years. ',
          <div className={classes.paragraph_break}></div>,
          'Following UX principles promoted by The Alzheimers’ Society; the entire experience is designed to limit confusion, with no advertisements, calls to action or other confusing distractions and tactics.',
        ]}
      />
      <FeatureBox
        heading="Focus on cognitive health and connectedness"
        feature_image="/features_cognitive_health.png"
        image_width="240px"
        image_height="232px"
        aligned="left"
        text={[
          'The features of Dialography are built directly to address the loss of self-sense and social connectedness that comes with old age.',
          <div className={classes.paragraph_break}></div>,
          'The value of shared reminiscence therapy has been demonstrated to support healthy cognitive outcomes, increase feelings of comfort, and ease transitions.',
        ]}
      />
      <FeatureBox
        heading="Personalized shared experiences"
        feature_image="/features_personalized.png"
        image_width="240px"
        image_height="257px"
        aligned="right"
        text={[
          'With recordings and transcriptions securely stored in the cloud, Seniors become active participants in content creation and storytelling building a valuable legacy for their children, grandchildren and future generations.',
          <div className={classes.paragraph_break}></div>,
          'Activities are organized by person, place, and event, and are intelligently recommended using machine learning to support reminiscence.',
        ]}
      />
      <div className={classes.paragraph_break}></div>
      <Footer />
    </div>
  );
}
