import { Chip, Grid, Paper, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import { AuthContext } from '../../../contexts/auth';
import { PhotoContext } from '../../../contexts/photos';
import ActionButton from '../UI/ActionButton.js';
import ModalButton from '../UI/ModalButton.js';
import ModalWrapper from '../UI/ModalWrapper.js';
import AttachToGroup from './AttachToGroup.js';
import EditAlbum from './EditAlbum.js';
import Image from './Image.js';

export default function Album(Props) {
  const { album } = Props;

  const location = useLocation();
  const history = useHistory();
  const { currentUser, userInfo, updateUserInfo } = useContext(AuthContext);

  let userGroupInfo = userInfo.groupInfo ?? {};
  let userGroupList = Object.keys(userGroupInfo).map(id => ({ id, ...userGroupInfo[id] }));
  let albumUserGroups = userGroupList.filter(x => x.id in (album.groupInfo ?? {}));

  const { albumPhotosMap } = useContext(PhotoContext);
  let albumPhotos = albumPhotosMap[album.id];

  return (
    <Grid item container xs={12} md={8} direction="column">
      <Grid item>
        <Typography variant="h2">Album: {album.name}</Typography>
      </Grid>

      <Grid item>
        <Paper style={{ margin: 10, padding: 10 }}>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="h6">Attached To Groups</Typography>
              {albumUserGroups ? (
                <Grid>
                  {albumUserGroups.map(z => (
                    <Chip key={z.id} label={z.name} variant="outlined" />
                  ))}
                </Grid>
              ) : (
                <Grid>Not attached</Grid>
              )}

              <br />
              <br />
            </Grid>
            <Grid item xs={4}>
              <ModalButton text="Attach To Group" title="Attach To Group">
                <AttachToGroup album={album} />
              </ModalButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <ModalButton text="Edit Album" title="Edit Album">
        <EditAlbum album={album} albumPhotos={albumPhotos} />
      </ModalButton>
      <ActionButton text={'Back'} onClick={() => Props.close()} />

      <Grid item>
        <Typography variant="h6">Currently there are {albumPhotos.length} photos here</Typography>
      </Grid>

      <Grid item container direction="row">
        {albumPhotos.map(x => (
          <Grid xs={4} item key={x.id}>
            <ModalWrapper buttonComponent={<img src={x.url} height={100} />} title="Image">
              <Image imageID={x.id} />
            </ModalWrapper>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
