import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { auth } from '../../../services/firebase';
import { AuthContext, AuthProvider } from '../../../contexts/auth';
import { useHistory } from 'react-router';
import { Grid, Hidden } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles(theme => ({
  header: {
    gridArea: 'header',
    /* header */
    [theme.breakpoints.between('md', 'xl')]: {
      padding: '10px 0px 0px 25px',
      width: 'calc(100% - 30px)',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '10px 15px 0px 15px',
      width: 'calc(100% - 30px)',
    },
    margin: 'auto',
    maxWidth: '1400px',
  },
  headerLogo: {
    /* logo */
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    [theme.breakpoints.between('md', 'xl')]: {
      background: 'url(/logo.png)',
      backgroundSize: '100px 100px',
      width: '100px',
      height: '100px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      background: 'url(/logo-sm.png)',
      backgroundSize: '60px 60px',
      width: '60px',
      height: '60px',
    },
  },
  headerAppLogo: {
    /* logo */
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    background: 'url(/logo-sm.png)',
    backgroundSize: '50px 50px',
    width: '50px',
    height: '50px',
  },
  headerDialography: {
    /* Dialography */

    fontFamily: 'Sonsie One',
    fontStyle: 'normal',
    fontWeight: 'normal',
    alignItems: 'center',
    display: 'flex',
    height: '100%',

    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '52px',
      lineHeight: '60px',
      paddingLeft: '30px',
      width: '300px',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '34px',
      lineHeight: '40px',
      paddingLeft: '15px',
      width: '200px',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '28px',
      lineHeight: '36px',
      paddingLeft: '15px',
      width: '10px',
    },
    cursor: 'pointer',
    color: '#2D82B7',
  },
  headerAppDialography: {
    /* Dialography */

    fontFamily: 'Sonsie One',
    fontStyle: 'normal',
    fontWeight: 'normal',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    fontSize: '30px',
    lineHeight: '40px',
    paddingLeft: '15px',
    width: '10px',
    color: '#2D82B7',
  },
  headerText: {
    position: 'relative',
    height: '60px',

    /* H5 - Black */
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '24px',
      lineHeight: '28px',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px',
    },
    /* primary-500 */
    color: '#2D82B7',
  },
  rightNav: {
    width: '312px',
    position: 'relative',
    display: 'flex',
    flexFlow: 'row-reverse',
    alignItems: 'center',
  },
  titleText: {
    position: 'relative',
    display: 'flex',
    textDecoration: 'none  !important',
    alignItems: 'center',
    /* H5 - Black */
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '22px',
      lineHeight: '28px',
      height: '60px',
      marginRight: '25px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '13px',
      lineHeight: '17px',
      height: '40px',
      marginRight: '9px',
    },

    /* primary-500 */
    color: '#2D82B7',
    cursor: 'pointer',
    textDecoration: 'none  !important',
  },
  titleAppText: {
    position: 'relative',
    display: 'flex',

    alignItems: 'center',
    /* H5 - Black */
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '17px',
    height: '40px',
    marginRight: '9px',

    /* primary-500 */
    color: '#2D82B7',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  boxRectangle: {
    /* Rectangle 8 */

    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.between('md', 'xl')]: {
      height: '60px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      height: '40px',
    },
    background: '#2D82B7',
    cursor: 'pointer',
    marginRight: '20px',
  },
  boxAppRectangle: {
    /* Rectangle 8 */

    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    background: '#2D82B7',
    cursor: 'pointer',
    marginRight: '20px',
  },
  login: {
    /* Get Started */

    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '23px',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '20px',
      lineHeight: '23px',
      width: '135px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '14px',
      lineHeight: '17px',
      width: '85px',
    },

    color: '#FFFFFF',
    textDecoration: 'none',
  },
  appLogin: {
    /* Get Started */

    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '23px',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '17px',
    width: '85px',

    color: '#FFFFFF',
    textDecoration: 'none',
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const { pending, currentUser } = useContext(AuthContext);
  const history = useHistory();

  const logout = async () => {
    await auth.signOut();
    window.location.assign('/login');
    return;
  };
  return (
    <header className={classes.header}>
      <Grid container justifyContent="space-between">
        <Grid item container xs={12} sm={6}>
          <Grid item className={props.inApp ? classes.headerAppLogo : classes.headerLogo}></Grid>
          <Grid
            item
            className={props.inApp ? classes.headerAppDialography : classes.headerDialography}
            onClick={() => history.push('/')}
          >
            Dialography
          </Grid>
        </Grid>
        {currentUser ? (
          <Grid item container className={classes.rightNav} xs={12} sm={6}>
            <Grid item className={props.inApp ? classes.boxAppRectangle : classes.boxRectangle}>
              <div className={props.inApp ? classes.appLogin : classes.login} onClick={() => history.push('/account')}>
                Account
              </div>
            </Grid>
            <Grid item>
              <a
                href="true"
                onClick={() => logout()}
                className={props.inApp ? classes.titleAppText : classes.titleText}
              >
                Logout
              </a>
            </Grid>
            <Grid item>
              <a
                href="/new_dialog"
                onClick={() => history.push('/new_dialog')}
                className={props.inApp ? classes.titleAppText : classes.titleText}
              >
                New Dialog
              </a>
            </Grid>
          </Grid>
        ) : (
          <Grid item container className={classes.rightNav} xs={12} sm={6}>
            <Grid item className={classes.boxRectangle}>
              <a href="/login" onClick={() => history.push('/login')} className={classes.login}>
                Sign In
              </a>
            </Grid>
            <Hidden smDown>
              <Grid item>
                <a href="/join_beta" style={{ textDecoration: 'none' }}>
                  <div className={classes.titleText}>Join Beta</div>
                </a>
              </Grid>
            </Hidden>
            <Grid item>
              <a style={{ textDecoration: 'none' }} href="/features" onClick={() => history.push('/features')}>
                <div className={classes.titleText}>Features</div>
              </a>
            </Grid>
            <Grid item>
              <a
                style={{ textDecoration: 'none' }}
                href="/why_dialography"
                onClick={() => history.push('/why_dialography')}
              >
                <div className={classes.titleText}>Why Dialography? </div>
              </a>
            </Grid>
          </Grid>
        )}
      </Grid>
    </header>
  );
}
