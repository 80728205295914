import React, { useState, useContext, useEffect } from 'react';
import { auth, googleAuthProvider, firestore } from '../../../services/firebase';
import { useLocation, useHistory } from 'react-router';
import { CssBaseline, TextField, Checkbox, Link, Grid, Typography, Container, Button, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { AuthContext } from '../../../contexts/auth';

const useStyles = makeStyles(theme => ({
  create: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    margin: 'auto',
    width: '100%',
    maxWidth: '1440px',
    paddingBottom: '100px',
  },
  leftPane: {
    paddingLeft: '10px',
    paddingRight: '10px',
    [theme.breakpoints.between('md', 'xl')]: {
      paddingTop: '100px',
      paddingLeft: '100px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: '50px',
    },
  },
  createMediumText: {
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '24px',
      lineHeight: '28px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '16px',
      lineHeight: '20px',
      paddingLeft: '5px',
    },

    color: '#000000',
  },
  createSmallText: {
    fontSize: '20px',
    lineHeight: '23px',
    color: '#FFFFFF',
  },
  neutral400: {
    color: '#737581',
  },
  smallTextBold: {
    fontWeight: '600',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '18px',
      lineHeight: '21px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '16px',
      lineHeight: '19px',
      paddingRight: '6px',
    },
  },
  largeTextBold: {
    fontWeight: '600',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '42px',
      lineHeight: '49px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '32px',
      lineHeight: '37px',
    },
    textAlign: 'center',

    color: '#000000',
  },
  signUpHero: {
    backgroundRepeat: 'no-repeat',
    background: 'url(signup_hero.jpg)',
    width: '572px',
    height: '431px',
    marginTop: '70px',
  },
  inputbox: {
    width: '93%',

    '& .MuiOutlinedInput-input': {
      color: '#2D82B7',
    },
    '& .MuiInputLabel-root': {
      color: '#737581',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D82B7',
      borderWith: '10px',
      borderRadius: '0px',
    },
    '&:hover .MuiOutlinedInput-input': {
      color: '#2D82B7',
    },
    '&:hover .MuiInputLabel-root': {
      color: '#2D82B7',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D82B7',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D82B7',
    },
    [theme.breakpoints.between('md', 'xl')]: {
      margin: '5px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '5px',
    },
  },
  createButton: {
    color: '#FFFFFF',
    backgroundColor: '#2D82B7',
    borderRadius: '0px',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '20px',
      fontWeight: 'normal',
      lineHeight: '23px',
      width: '136px',
      height: '60px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '19px',
      width: '105px',
      height: '40px',
    },
  },
}));

export default function CreateAccount() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  // We Will Watch AUTH, if it changes kick this to onboarding
  const { currentUser, userInfo, updateUserInfo } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      // alert("Got user "+JSON.stringify(currentUser))
      history.push('/onboarding');
    }
  }, [currentUser]);

  const signInWithGoogle = () => auth.signInWithPopup(googleAuthProvider);
  ///

  const [email, set_email] = useState('');
  const [password, set_password] = useState('');
  const [passwordConfirm, set_passwordConfirm] = useState('');
  const [firstName, set_firstName] = useState('');
  const [lastName, set_lastName] = useState('');

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const registerWithEmailAndPassword = async (firstName, lastName, email, password) => {
    await auth.createUserWithEmailAndPassword(email, password);

    const res = await auth.signInWithEmailAndPassword(email, password);
    const user = res.user;
    // sleep stops the race condition when user is auto-created

    await firestore
      .collection('users')
      .doc(user.uid)
      .set(
        {
          uid: user.uid,
          name: firstName,
          lastName: lastName,
          email,
        },
        { merge: true }
      );
    return;
  };

  const handleCreate = async () => {
    await registerWithEmailAndPassword(firstName, lastName, email, password);
    history.push('/onboarding');
  };

  let errorMap = {
    'Passwords Dont Match': password != passwordConfirm,
    'Password Needs To Be Longer': password.length < 5,
    'Enter Email': email.length < 5,
    'Enter First Name': firstName.length < 2,
    'Enter Last Name': lastName.length < 2,
  };

  let errors = Object.keys(errorMap).filter(x => errorMap[x]);

  const isValid = errors.length == 0;

  return (
    <div style={{ padding: '0px' }}>
      <Header />
      <Grid container className={classes.create}>
        <Grid item container md={6} className={classes.leftPane}>
          <Grid item style={{ paddingBottom: '25px' }}>
            <Typography className={classes.largeTextBold}>Sign up to start a Dialog</Typography>
          </Grid>
          <Grid item container style={{ paddingBottom: '10px' }}>
            <Typography variant="h3">Create Account</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth size="large" onClick={signInWithGoogle} variant="contained" color="primary">
              <i className="fab fa-google"></i>Sign Up With Google
            </Button>
          </Grid>

          <Grid item container style={{ paddingBottom: '10px' }}>
            <Typography variant="p">or create an account with your email...</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="First Name"
              variant="outlined"
              className={classes.inputbox}
              required
              id="firstname"
              name="firstname"
              autoComplete="off"
              value={firstName}
              onChange={e => set_firstName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Last Name"
              variant="outlined"
              className={classes.inputbox}
              required
              id="lastname"
              name="lastname"
              autoComplete="off"
              value={lastName}
              onChange={e => set_lastName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              className={classes.inputbox}
              required
              autoComplete="off"
              value={email}
              onChange={e => set_email(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              className={classes.inputbox}
              required
              name="password"
              autoComplete="off"
              id="password"
              value={password}
              onChange={e => set_password(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Confirm Password"
              variant="outlined"
              type="password"
              className={classes.inputbox}
              required
              name="confirm_password"
              id="confirm_password"
              autoComplete={'off'}
              value={passwordConfirm}
              onChange={e => set_passwordConfirm(e.target.value)}
            />
          </Grid>
          <Grid item>{errors.length ? errors.join(', ') : null}</Grid>
          <Grid item container>
            <Grid xs={8} item className={classes.smallTextBold}></Grid>
            <Grid xs={4} item>
              <Hidden only={['md', 'lg', 'xl']}>
                <Button
                  disabled={errors.length > 0}
                  onClick={handleCreate}
                  variant="contained"
                  className={classes.createButton}
                  size="small"
                  disableElevation
                >
                  Create
                </Button>
              </Hidden>
              <Hidden only={['xs', 'sm']}>
                <Button
                  disabled={errors.length > 0}
                  onClick={handleCreate}
                  variant="contained"
                  className={classes.createButton}
                  size="large"
                  disableElevation
                >
                  Create
                </Button>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
        <Hidden only={['xs', 'sm']}>
          <Grid item md={6} className={classes.signUpHero}></Grid>
        </Hidden>
      </Grid>
      {/* <Footer /> */}
    </div>
  );
}
