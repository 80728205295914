import { useEffect } from 'react';
import { DataTrack as IDataTrack } from 'twilio-video';
import useEventContext from '../../hooks/useEventContext/useEventContext';

interface DataTrackProps {
  track: IDataTrack;
}

export default function DataTrack({ track }: DataTrackProps) {
  const { event, addEvent } = useEventContext();

  useEffect(() => {
    track.on('message', message => {
      addEvent(message);
    });
  }, []);

  return null;
}
