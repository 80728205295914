import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ShareIcon from '@material-ui/icons/Share';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import { isMobile } from '../../../utils';
import Menu from './Menu/Menu';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import useEventContext from '../../hooks/useEventContext/useEventContext';
import useActivityContext from '../../hooks/useActivityContext/useActivityContext';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deviceButton: {
      color: '#2D82B7',
      backgroundColor: '#FFFFFF',
      border: '1px solid #2D82B7',
      borderRadius: '0px',
      fontSize: '20px',
      fontWeight: 'normal',
      lineHeight: '23px',
      //   width: '196px',
      height: '45px',
      margin: '5px',
    },
    leaveButton: {
      backgroundColor: '#D0454C',
      color: '#FFFFFF',
      borderRadius: '0px',
      fontSize: '20px',
      fontWeight: 'normal',
      lineHeight: '23px',
      //   width: '196px',
      height: '45px',
      margin: '5px',
    },
    shareButton: {
      fontSize: '20px',
      fontWeight: 'normal',
      lineHeight: '23px',
      borderRadius: '0px',
      color: '#FFFFFF',
      backgroundColor: '#3F8F5F',
      //   width: '196px',
      height: '45px',
      margin: '5px',
    },
    closeButton: {
      fontSize: '20px',
      fontWeight: 'normal',
      lineHeight: '23px',
      borderRadius: '0px',
      color: '#D0454C',
      backgroundColor: '#FFFFF',
      //   width: '196px',
      height: '45px',
      margin: '5px',
      border: '1px solid #2D82B7',
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const { room } = useVideoContext();

  return (
    <Grid container justifyContent="flex-end" className={classes.right_bar}>
      <Grid item>
        <ToggleAudioButton className={classes.deviceButton} disabled={isReconnecting} />
      </Grid>
      <Grid item>
        <ToggleVideoButton className={classes.deviceButton} disabled={isReconnecting} />
      </Grid>
      <Grid item>
        <Button variant="contained" className={classes.shareButton} onClick={''}>
          {/* <ShareIcon style={{ paddingRight: '5px' }} />  */}
          Invite
        </Button>
      </Grid>
      <Grid item>
        <EndCallButton className={classes.leaveButton} />
      </Grid>
    </Grid>
  );
}
