import { Card, CardActions, CardContent, CardHeader, Chip, Grid, Paper } from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import { AuthContext } from '../../../contexts/auth';
import ModalButton from '../UI/ModalButton.js';
import Album from './Album.js';

export default function AlbumCard({ album }) {
  const history = useHistory();
  const { userInfo } = useContext(AuthContext);

  let userGroupInfo = userInfo.groupInfo ?? {};
  let userGroupList = Object.keys(userGroupInfo).map(id => ({ id, ...userGroupInfo[id] }));
  let albumUserGroups = userGroupList.filter(z => z.id in (album.groupInfo ?? {}));
  return (
    <Grid item xs={4}>
      <Card>
        <CardHeader title={album.name} />
        <CardContent>
          {/* <Typography variant="h5">Group Name</Typography> */}

          <Grid item>
            {albumUserGroups.length ? (
              <Paper style={{ margin: 10, padding: 10 }}>
                {albumUserGroups.map(z => (
                  // groupLookup(z).name
                  <Chip key={z.id} label={z.name} variant="outlined" />
                ))}
              </Paper>
            ) : null}
          </Grid>
        </CardContent>
        <CardActions>
          <ModalButton key={album.id} text={'Open'} title={album.name}>
            <Album album={album} />
          </ModalButton>
        </CardActions>
      </Card>
    </Grid>
  );
}
