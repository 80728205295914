// @flow

import React, { useState } from 'react';
import type { Question } from '../material-survey-format.js.flow';
import checkValidator from './validators.js';

export default (question: Question, onChangeAnswer: Function, backupAnswer: any, actionManager: any) => {
  const { validators = [], defaultAnswer = backupAnswer } = question;
  const [state, changeStateHook] = useState({
    answer: defaultAnswer,
    error: null,
  });

  const changeState = payload => {
    if (actionManager && actionManager.constructor == Object && actionManager.registerAction) {
      // alert("And inside here")
      actionManager.registerAction({ type: 'surveyAnswer', question: question.name, payload: payload });
    }
    changeStateHook(payload);
  };
  if (actionManager && actionManager.constructor == Object && actionManager.registerActionHandler) {
    actionManager.registerActionHandler('surveyAnswer' + question.name, payload => {
      let action = payload['action'];
      if (action && action['type'] == 'surveyAnswer' && action['question'] == question.name) {
        changeStateHook(action['payload']);
      }
    });
  }

  return [
    state,
    (newAnswer: any) => {
      for (const validator of validators) {
        if (!checkValidator(validator, newAnswer)) {
          changeState({
            answer: newAnswer,
            error: validator.text || 'Invalid input, please correct!',
          });
          return;
        }
      }
      const isNumeric = validators.map(v => v.type).includes('numeric');
      if (typeof newAnswer === 'string' && isNumeric) {
        onChangeAnswer(parseFloat(newAnswer));
      } else {
        onChangeAnswer(newAnswer);
      }
      changeState({ answer: newAnswer, error: null });
    },
  ];
};
