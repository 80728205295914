import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import PaddedLayout from './PaddedLayout';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogWrapper(Props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //   Bind to an external OPEN function
  useEffect(() => {
    setOpen(Props.open);
  }, [Props.open]);

  return (
    <div style={{ width: '100%' }}>
      {React.cloneElement(Props.buttonComponent, { onClick: handleClickOpen })}
      <Dialog fullScreen open={open ?? false} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {Props.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <PaddedLayout>
          <Paper>
            <div style={{ marginTop: 15, margin: 5 }}>
              <center>{React.cloneElement(Props.children, { close: handleClose })}</center>
            </div>
            <Button fullWidth variant={'outlined'} onClick={handleClose}>
              Close
            </Button>
          </Paper>
        </PaddedLayout>
      </Dialog>
    </div>
  );
}
