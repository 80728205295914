import { makeStyles } from '@material-ui/core/styles';
import AccessibilityNew from '@material-ui/icons/AccessibilityNew';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Button from '@mui/material/Button';
import React, { useEffect } from 'react';
import LoadFireStoreCollection from '../loadFireStoreCollection.js';
import useSharedState from '../useSharedState.js';
import useDialogContext from '../../../hooks/useDialogContext/useDialogContext';
import { functions } from '../../../../services/firebase';
import PublicGoogleSheetsParser from 'public-google-sheets-parser';

const getGroupQuestions = functions.httpsCallable('getGroupQuestions');

const bgImages = ['/inspo1.jpg', '/inspo2.jpg', '/inspo3.jpg'];

let bgImage = bgImages[Math.floor(Math.random() * bgImages.length)];

const useStyles = makeStyles(theme => ({
  bgImage: {
    display: 'flex',
    background: 'url(' + bgImage + ')',
    width: '100%',
    height: '100%',
    zIndex: '1',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '0.7',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  questionBox: {
    width: '60%',
  },
  question: {
    backgroundColor: '#000000',
    opacity: '0.9',
    color: '#FFFFFF !important',
    fontSize: '42px !important',
    fontWeight: '300 !important',
    lineHeight: '48px !important',
    fontFamily: 'Roboto',
    padding: '70px',
  },
  nextButton: {
    backgroundColor: '#276738 !important',
    color: '#FFFFFF',
    borderRadius: '0px !important',
    fontSize: '14px !important',
    fontWeight: 'normal',
    lineHeight: '23px',
    width: '196px',
    height: '55px',
    margin: '5px !important',
  },
  canvas: {
    // backgroundColor: '#A1DAD2',
    width: '100%',
    height: '100% !important',
    paddingTop: '25px',
    overflowY: 'scroll',
  },
  main_bar: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Roboto',
    width: '100%',
    padding: ' 20px 0px 20px 85px',
  },
  main_title: {
    fontSize: '42px',
    color: '#2D82B7',
    fontWeight: '700',
  },
  main_subtitle: {
    fontSize: '14px',
    color: '#2D82B7',
    fontWeight: '300',
    paddingLeft: ' 20px',
  },
  topic_view: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
  },
  topic_box: {
    width: '275px',
    backgroundColor: '#FFFFFF',
    margin: '20px 15px 20px 15px',
    padding: '2px',
  },
  topic_title: {
    display: 'flex',
    margin: 'auto',
    textAlign: 'center',
    justifyContent: 'center',
    width: '275px',
    color: '#2D82B7',
    fontSize: '40px',
    fontFamily: 'Roboto',
    fontWeight: '700',
    padding: '10px 0px 10px 0px',
    textDecoration: 'underline',
  },
  topic_image: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '275px',
    height: '170px',
  },
  topic_button_box: {
    backgroundColor: '#2D82B7',
    justifyContent: 'center',
    alignItems: 'middle',
    width: '275px',
    height: '75px',
    margin: 'auto',
    display: 'flex',
  },
  topic_button: {
    color: '#FFFFFF',
    border: '1px solid #FFFFFF',
    backgroundColor: '#276738',
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontSize: '14px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'middle',
    width: '175px',
    height: '40px',
    lineHeight: '40px',
  },
  topic_button_bar: {
    maxWidth: '900px',
    margin: 'auto',
    justifyContent: 'center',
  },
}));
function choose(choices) {
  var index = Math.floor(Math.random() * choices.length);
  return choices[index];
}

function Questions(props) {
  const classes = useStyles();

  const { isHost, groupId, group } = useDialogContext();
  const { sharedState, getFromSharedState, setSharedState } = useSharedState('Questions');

  const [question, setQuestion] = getFromSharedState({ stateName: 'question', defaultValue: null });
  const [topic, setTopic] = getFromSharedState({ stateName: 'topic', defaultValue: null });
  const [currentIndex, setCurrentIndex] = getFromSharedState({ stateName: 'currentIndex', defaultValue: 0 });

  const [questionList, set_questionList] = getFromSharedState({ stateName: 'questionList', defaultValue: [] });
  const [questionTopics, set_questionTopics] = getFromSharedState({ stateName: 'questionTopics', defaultValue: [] });

  //***** LOAD IN THE TOPICS

  // const getQuestions_fromSheets = async () => {

  //   let parser = new PublicGoogleSheetsParser()
  //   parser.parse("1SrYlU7Fljmyaxc-uQl5AWo4Z1TIYVczdghZsYBQPN0w", 'topics').then((items) => {
  //       alert("Got items " + JSON.stringify(items))

  //   })
  //   // Tabletop.init( { key: "https://docs.google.com/spreadsheets/d/1SrYlU7Fljmyaxc-uQl5AWo4Z1TIYVczdghZsYBQPN0w/edit?usp=sharing",
  //   //   callback: (data,tt)=>alert(JSON.stringify(data)),
  //   //   simpleSheet: true } )

  //   // set_questionList(myQuestionList);
  //   // // Only topics with questions
  //   // set_questionTopics(myQuestionTopics.filter(x => myQuestionList.filter(z => z.topicId == x.id).length > 0));
  // };

  const getQuestions_fromFunction = async () => {
    let res = await getGroupQuestions({ groupId });
    let myQuestionList = res.data.questionList.map((x, i) => ({ index: i, ...x }));
    let myQuestionTopics = res.data.questionTopics.map((x, i) => ({ index: i, ...x }));

    set_questionList(myQuestionList);
    // Only topics with questions
    set_questionTopics(myQuestionTopics.filter(x => myQuestionList.filter(z => z.topicId == x.id).length > 0));
  };

  useEffect(() => {
    // the host is resposible for getting the questions
    if (isHost && questionList.length == 0) {
      getQuestions_fromFunction();
    }
  }, [group]);

  //******** */

  // handle onclick event
  const changeTopic = async topic => {
    let topicQuestions = questionList.filter(x => x.topicId == topic.id);
    setQuestion(choose(topicQuestions));
    setTopic(topic);
  };
  const newQuestion = () => {
    setQuestion(choose(questionList.filter(x => x.topicId == topic.id)));
  };

  let numObjects = questionTopics.length;
  let numObjectsPerPage = 3;

  let nextPage = () => {
    if (numObjects <= currentIndex + numObjectsPerPage) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + numObjectsPerPage);
    }
  };

  let lastPage = () => {
    if (currentIndex < numObjectsPerPage) {
      setCurrentIndex(numObjects - numObjectsPerPage);
    } else {
      setCurrentIndex(currentIndex - numObjectsPerPage);
    }
  };

  if (!topic) {
    return (
      <div className={classes.canvas}>
        <div className={classes.main_bar}>
          <div className={classes.main_title}>Select a Topic</div>
          <div className={classes.main_subtitle}>
            Topics are thematic based conversations you can start with your family members
          </div>
          {/* <div>{JSON.stringify(questionList,false,2)}</div>
           <div>{JSON.stringify(questionTopics,false,2)}</div> */}
        </div>
        <div className={classes.topic_view}>
          {questionTopics.slice(currentIndex, currentIndex + 3).map((obj, index) => (
            <div className={classes.topic_box}>
              <div className={classes.topic_title}>{obj.name}</div>
              <div className={classes.topic_image}>
                <img src={obj.image} width="275px" height="170px" />
              </div>
              <div className={classes.topic_button_box}>
                <div
                  className={classes.topic_button}
                  onClick={() => {
                    changeTopic(obj);
                  }}
                >
                  Start Conversation
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={classes.topic_button_bar}>
          <Button variant="contained" className={classes.nextButton} style={{ float: 'left' }} onClick={lastPage}>
            <NavigateBefore fontSize="small" style={{ paddingRight: '5px' }} /> Previous Topics
          </Button>
          <Button variant="contained" className={classes.nextButton} style={{ float: 'right' }} onClick={nextPage}>
            More Topics <NavigateNext fontSize="small" style={{ paddingRight: '5px' }} />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.bgImage} id="bgImage">
      <div className={classes.questionBox}>
        <div className={classes.question}>{question.question}</div>
        <div className={classes.buttonBar}>
          <Button variant="contained" className={classes.nextButton} onClick={() => newQuestion()}>
            <NavigateNext fontSize="small" style={{ paddingRight: '5px' }} /> Next Question
          </Button>
          <Button variant="contained" className={classes.nextButton} onClick={() => setTopic(null)}>
            <AccessibilityNew style={{ paddingRight: '5px' }} /> New Topic
          </Button>
        </div>
      </div>
    </div>
  );
}
export default Questions;
