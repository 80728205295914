import { Button, FormControl, FormControlLabel, FormGroup, Grid, Switch, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../contexts/auth';
import { functions } from '../../../services/firebase';
import AddPhotos from '../Albums/AddPhotos';
import ModalWrapper from '../UI/ModalWrapper';

const attachedAlbumToGroup = functions.httpsCallable('attachedAlbumToGroup');
const removeAlbumFromGroup = functions.httpsCallable('removeAlbumFromGroup');

export default function AttachAlbumForm({ group, close }) {
  // value is a {[id]:{name:..}} for attached albums

  const { userInfo } = useContext(AuthContext);

  let userAlbums = userInfo.albumInfo ?? {};
  let userAlbumsList = Object.keys(userAlbums).map(id => ({ id, ...userAlbums[id] }));

  const [groupAlbums, set_groupAlbums] = useState(Object.keys(group.albumInfo ?? {}));

  const [hasEdit, set_hasEdit] = useState(false);

  // if (userAlbumsList.length == 0) {
  //   return <div>You have no photo albums</div>;
  // }
  const handleClose = () => {
    close();
  };
  const handleSubmit = async () => {
    let removedAlbumIds = Object.keys(group.albumInfo).filter(x => !groupAlbums.includes(x));
    for (var i = 0; i < groupAlbums.length; i++) {
      await attachedAlbumToGroup({ albumId: groupAlbums[i], groupId: group.id });
    }

    for (var i = 0; i < removedAlbumIds.length; i++) {
      await removeAlbumFromGroup({ albumId: removedAlbumIds[i], groupId: group.id });
    }
    handleClose();
  };
  // END GETTING PHOTOS FROM FIREBASE

  return (
    <Grid item container xs={12} spacing={5} direction="row" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h6">Add Album To Circle</Typography>
        <Typography variant="p">
          Select which albums you would like to attach to this circle. These will be available in all dialogs in this
          circle.
        </Typography>
      </Grid>

      <Grid item>
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            {userAlbumsList.map(a => (
              <FormControlLabel
                control={
                  <Switch
                    checked={groupAlbums.includes(a.id)}
                    onChange={() => {
                      if (groupAlbums.includes(a.id)) {
                        set_groupAlbums(z => z.filter(x => x !== a.id));
                      } else {
                        set_groupAlbums(z => [a.id, ...z]);
                      }
                      set_hasEdit(true);
                    }}
                    name={a.name}
                  />
                }
                label={a.name}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <ModalWrapper
          buttonComponent={
            <Button size="small" variant="outlined">
              Create New Album
            </Button>
          }
        >
          <AddPhotos />
        </ModalWrapper>
      </Grid>

      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          disabled={hasEdit == false}
          disableElevation
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
}
