import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  feature_box_left: {
    fontFamily: 'Roboto',
    display: 'grid',
    [theme.breakpoints.between('md', 'xl')]: {
      gridTemplateAreas: `
	  		            'feature_header feature_header '
	                  	'feature_text feature_image'
	  		        `,
      gridTemplateColumns: '60% 40%',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      gridTemplateAreas: `
	              'feature_header'
	              'feature_image '
	              'feature_text'
	      `,
      gridTemplateColumns: '100%',
    },
    width: '100vw',
    height: '100%',
    maxWidth: '1440px',
    margin: 'auto',
  },
  feature_box_right: {
    fontFamily: 'Roboto',
    display: 'grid',
    [theme.breakpoints.between('md', 'xl')]: {
      gridTemplateAreas: `
	  		            'feature_header feature_header '
	                  	'feature_image feature_text'
	  		        `,
      gridTemplateColumns: '40% 60%',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      gridTemplateAreas: `
	              'feature_header'
	              'feature_image '
	              'feature_text'
	      `,
      gridTemplateColumns: '100%',
    },
    width: '100vw',
    height: '100%',
    maxWidth: '1440px',
    margin: 'auto',
  },
  feature_header: {
    gridArea: 'feature_header',
    fontWeight: '700',
    display: 'grid',
    margin: 'auto',

    alignItems: 'center',
    [theme.breakpoints.between('md', 'xl')]: {
      padding: '20px 25px 20px 85px',
      fontSize: '36px',
      lineHeight: '42px',
      width: 'calc(100vw - 110px)',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '26px',
      lineHeight: '28px',
      padding: '20px 15px 20px 15px',
      justifyContent: 'center',
      textAlign: 'center',
      width: 'calc(100vw - 30px)',
    },
  },
  feature_image: {
    gridArea: 'feature_image',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'block',
    [theme.breakpoints.between('md', 'xl')]: {},
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100vw',
      height: '` ',
    },
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  feature_image_right: {
    [theme.breakpoints.between('md', 'xl')]: {
      marginLeft: '170px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: '15px',
      marginBottom: '25px',
    },
  },
  feature_image_left: {
    [theme.breakpoints.between('md', 'xl')]: {
      marginRight: '170px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: '15px',
      marginBottom: '25px',
    },
  },
  feature_text: {
    gridArea: 'feature_text',
    fontWeight: '300',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '22px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '18px',
    },
    backgroundColor: '#F8F8F8',
  },
  feature_text_left: {
    [theme.breakpoints.between('md', 'xl')]: {
      padding: '50px 50px 50px 120px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '30px 40px 30px 40px',
    },
  },
  feature_text_right: {
    [theme.breakpoints.between('md', 'xl')]: {
      padding: '50px 100px 50px 50px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '30px 40px 30px 40px',
    },
  },
  paragraph_break: {
    marginTop: '25px',
  },
}));
export default function FeatureBox(props) {
  const classes = useStyles();

  return (
    <div className={props.aligned === 'left' ? classes.feature_box_left : classes.feature_box_right}>
      <div className={classes.feature_header}>{props.heading}</div>
      <Hidden smDown>
        <div
          className={[
            classes.feature_image,
            props.aligned === 'left' ? classes.feature_image_left : classes.feature_image_right,
          ].join(' ')}
          style={{
            backgroundImage: 'url(' + props.feature_image + ')',
            width: props.image_width,
            height: props.image_height,
          }}
        ></div>
      </Hidden>
      <Hidden mdUp>
        <div
          className={[
            classes.feature_image,
            props.aligned === 'left' ? classes.feature_image_left : classes.feature_image_right,
          ].join(' ')}
          style={{
            backgroundImage: 'url(' + props.feature_image + ')',
            width: 'calc(' + props.image_width + ' - 70px)',
            height: 'calc(' + props.image_height + ' - 70px)',
          }}
        ></div>
      </Hidden>
      <div
        className={[
          classes.feature_text,
          props.aligned === 'left' ? classes.feature_text_left : classes.feature_text_right,
        ].join(' ')}
      >
        {props.text}
      </div>
    </div>
  );
}
