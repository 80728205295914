import Button from '@mui/material/Button';
import React from 'react';

export default function ActionButton(Props) {
  return (
    <Button
      variant="outlined"
      style={{
        borderRadius: '4px',
        textTransform: 'none',
        color: 'rgb(40, 42, 43)',
        // fontSize: '1.3rem',
        border: '1px solid lightGrey',
        '&:hover': {
          border: '2px solid rgb(1, 85, 137)',
        },
        padding: 5,
        margin: 5,
      }}
      // variant="outlinedPrimary"
      fullWidth
      {...Props}
    >
      {Props.text ? Props.text : 'Open'}
    </Button>
  );
}
