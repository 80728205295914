import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { auth } from '../../../services/firebase';
import { useLocation, useHistory } from 'react-router';
import { useState, useContext } from 'react';
import { AuthContext, AuthProvider } from '../../../contexts/auth';

import { Widget, addResponseMessage } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import axios from 'axios';

const id = Math.random();

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Chatbot() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  const [message, setMessage] = useState('');

  //   const addResponseMessage = (m) => {
  //       setMessage(current_m => m+current_m)
  //   }
  const NerdPhoto =
    'https://lh3.googleusercontent.com/dfg6wH6i5rqF_08GEaV4Sgc4PxDDiGnjv-JjmMnosA4i8uP9AEoa9elMwcioPx7SpCatA-59xmetgRIVXuc40OCfNMtUZxtBeb6xHYY8TpSo4S55lzVN74KU-rROLD__tm-P8SrN_ks=w2400';
  const [showNerdPhoto, setShowNerdPhoto] = useState(false);

  const handleNewUserMessage = message => {
    axios
      .post('https://test-chatbot-backend-6243.twil.io/test_chat', {
        message,
        id,
      })
      .then(response => {
        response.data.response.says.forEach(say => {
          addResponseMessage(say.text);
        });
      });
  };

  return <Widget handleNewUserMessage={handleNewUserMessage} />;
}
