import { Button, Card, CardActions, CardContent, CardHeader, Chip } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';

export default function GroupCard({ group }) {
  const history = useHistory();

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader title={group.name ? group.name : 'Your Group'} />
      <CardContent>
        {/* <Typography variant="h5">Group Name</Typography> */}

        {Object.keys(group.userInfo).map(uid => (
          <Chip key={uid} label={group.userInfo[uid].name} variant="outlined" />
        ))}
      </CardContent>
      <CardActions>
        <Button fullWidth color="primary" variant="contained" onClick={() => history.push('/group/' + group.id)}>
          Open
        </Button>
      </CardActions>
    </Card>
  );
}
