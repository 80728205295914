import { Button, Paper, Typography } from '@material-ui/core';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { auth } from '../../../services/firebase';
import PaddedLayout from './PaddedLayout';
import ScrollToTop from './ScrollToTop';
import { AuthContext } from '../../../contexts/auth';

export default function ScreenLayout(Props) {
  const history = useHistory();
  const { currentUser, userInfo, groupList } = useContext(AuthContext);
  return (
    <PaddedLayout>
      <ScrollToTop>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Dialography
            </Typography>
            <Button
              color="inherit"
              onClick={() => {
                history.push('/account');
              }}
            >
              Home
            </Button>

            {currentUser ? (
              <Button
                color="inherit"
                onClick={async () => {
                  await auth.signOut();
                  window.location.assign('/');
                }}
              >
                Logout
              </Button>
            ) : (
              <Button
                color="inherit"
                onClick={async () => {
                  history.push('/login');
                }}
              >
                Login
              </Button>
            )}
          </Toolbar>
        </AppBar>

        <Paper style={{ padding: 10, margin: 0 }}>{Props.children}</Paper>
      </ScrollToTop>
    </PaddedLayout>
  );
}
