import { Button, Card, CardActions, CardContent, CardHeader, Chip, Grid, Paper, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import GroupCard from './GroupCard.js';
import { useHistory } from 'react-router';
import { AuthContext } from '../../../contexts/auth';
import { auth } from '../../../services/firebase';

export default function GroupList({ groupList }) {
  return (
    <Grid container sx={{ margin: 5 }} spacing={0} direction="row" alignItems="center">
      {/* {J} */}
      {groupList.map(g => (
        <GroupCard key={g.id} group={g} />
      ))}
    </Grid>
  );
}
