import { Button, Grid, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';

import { functions } from '../../../services/firebase';

const removeUserFromGroup = functions.httpsCallable('removeUserFromGroup');

export default function GroupUsersForm({ group, close }) {
  // value holds a list of current users invited
  // take this from the group.userInfo object

  let groupUsersInfo = group.userInfo ?? {};
  let groupUsersList = Object.keys(groupUsersInfo).map(id => ({ id, ...groupUsersInfo[id] }));

  const handleClose = () => {
    close();
  };

  const handleRemoveUser = async uid => {
    await removeUserFromGroup({ userId: uid, groupId: group.id });
  };

  return (
    <Grid container style={{ width: '100%', margin: 0 }} spacing={5} direction="row" alignItems="center">
      <Grid item container xs={12} justifyContent="center">
        <Typography variant="h4">Edit The Users In Your Group</Typography>
      </Grid>

      <Grid item container xs={12} justifyContent="center">
        {groupUsersList.map(x => (
          <Grid item key={x.id}>
            <Typography variant="h4">{x.name}</Typography>
            <Button fullWidth color="secondary" onClick={() => handleRemoveUser(x.id)}>
              Remove User From Group
            </Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
