import React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

export default function ImageCard(props) {
  let url = props.photo.url;

  return (
    <Card>
      <CardMedia
        component="img"
        height={props.height ? props.height : '200px'}
        image={url} // "https://picsum.photos/id/"+getRandomInt(500)+"/200/300"}
        alt=""
      />
      <CardContent></CardContent>
      <CardActions disableSpacing>
        <Button fullWidth variant="outlined" onClick={props.viewCallBack}>
          Open
        </Button>
        {/* <IconButton aria-label="share">
   Share
  </IconButton> */}
      </CardActions>
    </Card>
  );
}
