import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Participant from '../Participant/Participant';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useDialogContext from '../../hooks/useDialogContext/useDialogContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflowY: 'auto',
      background: 'rgb(79, 83, 85)',
      gridArea: '1 / 2 / 1 / 3',
      zIndex: 5,
      position: 'absolute',
      right: '0',
      bottom: '0',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        gridArea: '2 / 1 / 3 / 3',
        overflowY: 'initial',
        overflowX: 'auto',
        display: 'flex',
      },
    },
    transparentBackground: {
      background: 'transparent',
    },
    scrollContainer: {},
    innerScrollContainer: {
      width: `calc(${theme.sidebarWidth}px - 3em)`,
      padding: '1.5em 0',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        padding: `${theme.sidebarMobilePadding}px`,
        display: 'flex',
      },
    },
  })
);

export default function ParticipantList(Props) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const localParticipant = room.localParticipant;
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const mainParticipant = useMainParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;

  const { setParticipants } = useDialogContext();

  useEffect(() => {
    let participantList = participants.map(x => ({ id: x.identity.split(':::')[1], name: x.identity.split(':::')[0] }));
    setParticipants(participantList);
  }, [participants]);

  // if (participants.length === 0) return null; // Don't render this component if there are no remote participants.

  const { width, height } = Props;

  // we need to fit in all the participants here
  const numParticipants = participants.length + 1; // includes us

  // approach, divide the longer size by 2 until you get enough squares
  let participantWidth = width;
  let participantHeight = height;

  while (Math.floor(width / participantWidth) * Math.floor(height / participantHeight) < numParticipants) {
    // code block to be executed
    if (participantWidth < participantHeight) {
      participantHeight = participantHeight / 2;
    } else {
      participantWidth = participantWidth / 2;
    }
  }

  let participantSize = Math.min(participantWidth, participantHeight);

  return (
    <Grid container style={{ height: height, width: width }}>
      <Grid item style={{ height: participantSize, width: participantSize }}>
        <Participant participant={localParticipant} isLocalParticipant={true} />
      </Grid>
      {participants.map(participant => {
        // const isSelected = participant === selectedParticipant;
        // const hideParticipant = Props.activitySet
        //   ? false
        //   : participant === mainParticipant && participant !== screenShareParticipant && !isSelected;
        return (
          <Grid
            key={participant.sid}
            item
            style={{ border: '1px solid red', height: participantSize, width: participantSize }}
          >
            <Participant
              key={participant.sid}
              participant={participant}
              // isSelected={participant === selectedParticipant}
              // onClick={() => setSelectedParticipant(participant)}
              // hideParticipant={hideParticipant}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
