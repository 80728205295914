import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import React, { useEffect, useRef } from 'react';
// import ImageSearch from './ImageSearch.js';
import AttachAlbumForm from '../../../../Main/components/Groups/AttachAlbumForm';
import ModalWrapper from '../../../../Main/components/UI/ModalWrapper';
import { functions } from '../../../../services/firebase';
import useDialogContext from '../../../hooks/useDialogContext/useDialogContext';
import ListView from '../General/ListView.js';
import useParticipantInfo from '../useParticipantInfo.js';
import useSharedState from '../useSharedState.js';
import ImageCard from './ImageCard.js';
import ImageView from './ImageView.js';

const getGroupAlbumPhotos = functions.httpsCallable('getGroupAlbumPhotos');

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1), //grid padding
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function PhotoBrowser(props) {
  const ref = useRef(null);
  const { isHost, groupId, group } = useDialogContext();
  const { sharedState, getFromSharedState, setSharedState } = useSharedState('PhotoBrowser');
  const { participantInfo } = useParticipantInfo();

  // SHARED STATE MANAGEMENT
  const [generalSharedState, setGeneralSharedState] = getFromSharedState({
    stateName: 'generalSharedState',
    defaultValue: {},
  });

  const [activePhoto, setActivePhoto] = getFromSharedState({ stateName: 'activePhoto', defaultValue: null });
  const [photoList, setPhotoList] = getFromSharedState({ stateName: 'photoList', defaultValue: [] });
  const [albumList, setAlbumList] = getFromSharedState({ stateName: 'albumList', defaultValue: [] });

  const getGroupPhotos = async () => {
    let res = await getGroupAlbumPhotos({ groupId });
    setPhotoList(res.data.photoList.map((x, i) => ({ index: i, ...x })));
    setAlbumList(res.data.albumList.map((x, i) => ({ index: i, ...x })));
  };
  useEffect(() => {
    // the host is resposible for getting the photos
    if (isHost) {
      getGroupPhotos();
    }
  }, [group]);

  // ADD PHOTOS TO GROUP BUTTON
  const AddPhotosToGroup = (
    <ModalWrapper
      buttonComponent={
        <Button size="small" variant="outlined">
          Add Photos
        </Button>
      }
    >
      <AttachAlbumForm group={group} />
    </ModalWrapper>
  );

  if (photoList.length == 0) {
    if (!isHost) {
      return <div>Waiting for the HOST to select photos</div>;
    } else {
      return (
        <div>
          You are the HOST, choose the photos
          {AddPhotosToGroup}
        </div>
      );
    }
  }

  if (activePhoto) {
    let nextPhoto = activePhoto.index > photoList.length ? photoList[0] : photoList[activePhoto.index + 1];
    let prevPhoto = activePhoto.index == 0 ? photoList[-1] : photoList[activePhoto.index - 1];

    return (
      <div style={{ backgroundColor: 'white', overflowY: 'scroll', width: '100%', height: '100%' }}>
        <ImageView
          photoList={photoList}
          getFromSharedState={getFromSharedState}
          photo={activePhoto}
          nextCallBack={() => setActivePhoto(nextPhoto)}
          previousCallBack={() => setActivePhoto(prevPhoto)}
          backCallBack={() => setActivePhoto(null)}
          height={props.height}
          width={props.width}
        ></ImageView>
      </div>
    );
  }
  return (
    <div ref={ref} style={{ backgroundColor: 'white', overflowY: 'scroll', height: '100%', width: '100%' }}>
      {AddPhotosToGroup}{' '}
      <ListView
        objectList={photoList}
        getFromSharedState={getFromSharedState}
        showObjectComponent={photo => (
          <ImageCard
            photo={photo}
            viewCallBack={() => setActivePhoto(photo)}
            height={props.height - 300} // 300 is about the 2 buttons
            width={ref.current ? ref.current.offsetWidth / 4 : 0}
          />
        )}
      />
    </div>
  );
}
export default PhotoBrowser;
