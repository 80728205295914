import React from 'react';
import { makeStyles, Theme, Typography, Hidden } from '@material-ui/core';
import Swoosh from './swoosh';
import VideoLogo from './VideoLogo';
import TwilioLogo from './TwilioLogo';
import { useAppState } from '../../../state';
import UserMenu from './UserMenu/UserMenu';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    justifyContent: 'center',
    background: '#FFFFFF',
    gridArea: 'main',
    height: '62vh',
  },
  container: {
    position: 'relative',
    margin: 'auto',
  },
  innerContainer: {
    display: 'flex',
    width: '888px',
    height: '313px',
    borderRadius: '0px',
    border: '2px solid #74A186',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 'auto',
      width: 'calc(100% - 40px)',
      margin: 'auto',
      maxWidth: '400px',
    },
  },
  swooshContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    background: 'url(/topicbased_dialog.jpg)',
    width: '350px',
    height: '313px',
  },
  content: {
    display: 'flex',
    background: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 25px 5px 25px',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '2em',
    },
  },
  largeTextBold: {
    fontWeight: '600',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '42px',
      lineHeight: '49px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '32px',
      lineHeight: '37px',
    },
    textAlign: 'left',
    justifyContent: 'center',
    color: '#000000',
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();
  const { user } = useAppState();
  const location = useLocation();

  return (
    <div className={classes.background}>
      {user && location.pathname !== '/login' && <UserMenu />}
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <Hidden smDown>
            <div className={classes.swooshContainer}></div>
          </Hidden>
          <div className={classes.content}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default IntroContainer;
