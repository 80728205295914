import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppState } from '../../../state';
import { AuthContext } from '../../../contexts/auth';
import { PhotoContext, PhotoProvider } from '../../../contexts/photos.js';

export default function PrivateRoute({ children, ...rest }: RouteProps) {
  // const { isAuthReady, user } = useAppState();
  const { currentUser } = useContext(AuthContext);

  const renderChildren = currentUser;

  // if (!currentUser) {
  //   window.location.assign('/login');
  // }

  // return <Route {...rest} render={({ location }) => children} />;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        renderChildren ? (
          <PhotoProvider>{children}</PhotoProvider>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
