import React, { useState } from 'react';
import { styled, Theme } from '@material-ui/core/styles';

import Canvas from './components/Canvas/Canvas';
import Header from '../Main/components/Header/Header';

import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import Footer from '../Main/components/Footer/Footer';

const Container = styled('div')({
  display: 'grid',
  gridTemplateAreas: `
                    'header'
                    'main '
                    'footer'
                `,
  gridTemplateRows: '1fr auto',
  backgroundColor: '#FFFFFF',
  height: '95vh', // added here to get us to full screen
});

export default function App() {
  const roomState = useRoomState();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  return (
    <Container style={{ height: '95vh' }}>
      {roomState === 'disconnected' ? (
        <div>
          <Header inApp={true} />
          <PreJoinScreens />
          <Footer />
        </div>
      ) : (
        <Canvas />
      )}
    </Container>
  );
}
