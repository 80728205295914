import { Button, Card, CardActions, CardContent, CardHeader, Chip, Grid, Paper, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { AuthContext } from '../../../contexts/auth';
import { auth, googleAuthProvider, functions } from '../../../services/firebase';

import ActionButton from '../UI/ActionButton.js';
import ModalButton from '../UI/ModalButton.js';
import ModalWrapper from '../UI/ModalWrapper';
import PageHeading from '../UI/PageHeading';
import ScreenLayout from '../UI/ScreenLayout';
import NewGroup from '../Groups/NewGroup.js';

const createDialog = functions.httpsCallable('createDialog');
const createGroup = functions.httpsCallable('createGroup');

export default function AccountPage() {
  const [loading, setLoading] = useState(false);
  // const classes = useStyles();
  const history = useHistory();

  const signInWithGoogle = () => auth.signInWithPopup(googleAuthProvider);

  const { groupId } = useParams();
  const { currentUser, userInfo, groupList } = useContext(AuthContext);

  const startDialog = async () => {
    // alert("starting dialog in " + group.id)
    let res = await createDialog({ groupId: groupId });
    // alert(JSON.stringify(res))
    history.push('/dialog/' + res.data.dialogId);
  };

  // find the matching group
  if (groupList.filter(x => x.id == groupId).length > 0) {
    let group = groupList.filter(x => x.id == groupId)[0];

    return (
      <ScreenLayout>
        <Grid container spacing={5} direction="row" alignItems="center">
          {/* {J} */}
          <Grid item style={{ width: '100%' }}>
            <Typography variant="h4">Starting A New Dialog In {group.name} </Typography>
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <Paper style={{ padding: 10, margin: 10 }}>
              <Grid item xs={12}>
                <Button fullWidth size="large" onClick={startDialog} variant="contained" color="primary">
                  Begin Dialog!
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {/* <Footer /> */}
      </ScreenLayout>
    );
  }

  if (currentUser == null) {
    return (
      <ScreenLayout>
        {/* <Header /> */}

        <Grid container spacing={5} direction="row" alignItems="center">
          {/* {J} */}
          <Grid item style={{ width: '100%' }}>
            <Typography variant="h4">New Dialog</Typography>
            <Typography variant="p">
              Dialogs lets you have awesome conversions. A dialog occurs in a circle, which is a place that you will
              save and invite people too.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth size="large" onClick={signInWithGoogle} variant="contained" color="primary">
              <i className="fab fa-google"></i>Sign In With Google
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth size="large" onClick={() => history.push('/login')} variant="outlined" color="primary">
              Or... Go Login
            </Button>
          </Grid>
        </Grid>
      </ScreenLayout>
    );
  }

  return (
    <ScreenLayout>
      {/* <Header /> */}

      <Grid container spacing={5} direction="row" alignItems="center">
        {/* {J} */}
        <Grid item style={{ width: '100%' }}>
          <Typography variant="h4">New Dialog</Typography>
          <Typography variant="p">
            Dialogs lets you have awesome conversions. A dialog occurs in a circle, which is a place that you will save
            and invite people too.
          </Typography>
        </Grid>
        <Grid hidden={groupList.length == 0} item style={{ width: '100%' }}>
          <Paper style={{ padding: 10, margin: 10 }}>
            <Typography variant="h5">Select A Circles</Typography>

            {groupList.map(g => (
              <Button key={g.id} onClick={() => history.push('/new_dialog/' + g.id)} variant="outlined">
                {g.name}
              </Button>
            ))}
          </Paper>
        </Grid>

        <Grid item style={{ width: '100%' }}>
          <ModalWrapper
            title="Make A New Circle"
            buttonComponent={
              <Button color="secondary" fullWidth variant="outlined">
                Create New Circle For This Dialog
              </Button>
            }
          >
            <NewGroup />
          </ModalWrapper>
        </Grid>
      </Grid>
      {/* <Footer /> */}
    </ScreenLayout>
  );
}
