import React, { useState } from 'react';
import { auth } from '../../../services/firebase';
import { useLocation, useHistory } from 'react-router';
import { CssBaseline, TextField, Checkbox, Link, Grid, Typography, Container, Button, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const useStyles = makeStyles(theme => ({
  create: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    margin: 'auto',
    width: '100%',
    maxWidth: '1440px',
    paddingBottom: '100px',
  },
  leftPane: {
    paddingLeft: '10px',
    paddingRight: '10px',
    [theme.breakpoints.between('md', 'xl')]: {
      paddingTop: '100px',
      paddingLeft: '100px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: '50px',
    },
  },
  createMediumText: {
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '24px',
      lineHeight: '28px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '16px',
      lineHeight: '20px',
      paddingLeft: '5px',
    },
    width: '100%',
    color: '#000000',
  },
  createSmallText: {
    fontSize: '20px',
    lineHeight: '23px',
    color: '#FFFFFF',
  },
  neutral400: {
    color: '#737581',
  },
  smallTextBold: {
    fontWeight: '600',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '18px',
      lineHeight: '21px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '16px',
      lineHeight: '19px',
      paddingRight: '6px',
    },
  },
  largeTextBold: {
    fontWeight: '600',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '42px',
      lineHeight: '49px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '32px',
      lineHeight: '37px',
    },
    textAlign: 'center',

    color: '#000000',
  },
  signUpHero: {
    backgroundRepeat: 'no-repeat',
    background: 'url(signup_hero.jpg)',
    width: '572px',
    height: '431px',
    marginTop: '70px',
  },
  inputbox: {
    width: '93%',

    '& .MuiOutlinedInput-input': {
      color: '#2D82B7',
    },
    '& .MuiInputLabel-root': {
      color: '#737581',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D82B7',
      borderWith: '10px',
      borderRadius: '0px',
    },
    '&:hover .MuiOutlinedInput-input': {
      color: '#2D82B7',
    },
    '&:hover .MuiInputLabel-root': {
      color: '#2D82B7',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D82B7',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D82B7',
    },
    [theme.breakpoints.between('md', 'xl')]: {
      margin: '5px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '5px',
    },
  },
  createButton: {
    color: '#FFFFFF',
    backgroundColor: '#2D82B7',
    borderRadius: '0px',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '20px',
      fontWeight: 'normal',
      lineHeight: '23px',
      width: '136px',
      height: '60px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '19px',
      width: '105px',
      height: '40px',
    },
  },
}));

export default function CreateAccount() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [beta, setBeta] = useState(false);
  const [success, setSuccess] = useState(false);

  const formData = { email, firstName, lastName, beta };
  const handleSubmit = async () => {
    // alert(JSON.stringify(formData));

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    };
    await fetch('https://us-central1-dialography-29b8b.cloudfunctions.net/betaApplication', requestOptions);

    setSuccess(true);
  };

  if (success) {
    return (
      <div style={{ padding: '0px' }}>
        <Header />
        <Grid container className={classes.create}>
          <Grid item container md={6} className={classes.leftPane}>
            <Grid item>
              <Typography className={classes.largeTextBold}>Dialography Beta Testing</Typography>
            </Grid>
            <Grid item container>
              <Grid xs={8} item className={classes.createMediumText}>
                Thank you so much for your support! We really appreciate you.
              </Grid>
              <Grid xs={8} item className={classes.createMediumText}>
                <Button
                  onClick={() => history.push('/')}
                  variant="contained"
                  className={classes.createButton}
                  size="large"
                  disableElevation
                >
                  Return
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Hidden only={['xs', 'sm']}>
            <Grid item md={6} className={classes.signUpHero}></Grid>
          </Hidden>
        </Grid>
        <Footer />
      </div>
    );
  }

  return (
    <div style={{ padding: '0px' }}>
      <Header />
      <Grid container className={classes.create}>
        <Grid item container md={6} className={classes.leftPane}>
          <Grid item style={{ paddingBottom: '25px' }}>
            <Typography className={classes.largeTextBold}>Dialography Beta Testing</Typography>
          </Grid>
          <Grid item container style={{ paddingBottom: '10px' }}>
            <Grid item className={classes.createMediumText}>
              Add your name to be the first to know when its live, or to join our beta testers.
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="First Name"
              variant="outlined"
              className={classes.inputbox}
              required
              id="firstname"
              name="firstname"
              autoComplete="firstname"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Last Name"
              variant="outlined"
              className={classes.inputbox}
              required
              id="lastname"
              name="lastname"
              autoComplete="lastname"
              value={''}
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              className={classes.inputbox}
              required
              id="email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} item className={classes.smallTextBold}>
            Interested In Becoming A Beta Tester
            <Checkbox
              label="Join Beta Test"
              variant="outlined"
              required
              id="beta"
              name="beta"
              autoComplete=""
              value={beta}
              onChange={e => setBeta(e.target.value)}
            />
          </Grid>
          <Grid item container>
            <Grid xs={8} item className={classes.smallTextBold}></Grid>
            <Grid xs={4} item>
              <Hidden only={['md', 'lg', 'xl']}>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  className={classes.createButton}
                  size="small"
                  disableElevation
                >
                  Submit
                </Button>
              </Hidden>
              <Hidden only={['xs', 'sm']}>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  className={classes.createButton}
                  size="large"
                  disableElevation
                >
                  Submit
                </Button>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
        <Hidden only={['xs', 'sm']}>
          <Grid item md={6} className={classes.signUpHero}></Grid>
        </Hidden>
      </Grid>
      <Footer />
    </div>
  );
}
