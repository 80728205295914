import Button from '@mui/material/Button';

function ActionButton(props) {
  return (
    <Button variant="contained" style={{ minHeight: 50 }} fullWidth {...props}>
      {props.children}
    </Button>
  );
}

export default ActionButton;
