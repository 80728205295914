// @flow

export default [
  {
    name: 'New England',
    id: 'new-england',
    states: ['CT', 'ME', 'MA', 'NH', 'RI', 'VT'],
  },
  {
    name: 'Mid East',
    id: 'mid-east',
    states: ['DE', 'DC', 'MD', 'NJ', 'NY', 'PA'],
  },
  {
    name: 'Great Lakes',
    id: 'great-lakes',
    states: ['IL', 'IN', 'MI', 'OH', 'WI'],
  },
  {
    name: 'Plains',
    id: 'plains',
    states: ['IA', 'KS', 'MN', 'MO', 'NE', 'ND', 'SD'],
  },
  {
    name: 'Southeast',
    id: 'southeast',
    states: ['AL', 'AR', 'FL', 'GA', 'KY', 'LA', 'MS', 'NC', 'SC', 'TN', 'VA', 'WV'],
  },
  {
    name: 'Southwest',
    id: 'southwest',
    states: ['AZ', 'NM', 'OK', 'TX'],
  },
  {
    name: 'Rocky Mountains',
    id: 'rocky-mountains',
    states: ['CO', 'ID', 'MT', 'UT', 'WY'],
  },
  {
    name: 'Far West',
    id: 'far-west',
    states: ['AK', 'CA', 'HI', 'NV', 'OR', 'WA'],
  },
  {
    name: 'Outlying Areas',
    id: 'outlying-areas',
    states: ['AS', 'FM', 'GU', 'MH', 'MP', 'PR', 'PW', 'VI'],
  },
];
