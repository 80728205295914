import React, { createContext, useState, useEffect, useRef } from 'react';
import useEventContext from '../../hooks/useEventContext/useEventContext';
import useActivityContext from '../../hooks/useActivityContext/useActivityContext';
import useDialogContext from '../../hooks/useDialogContext/useDialogContext';

import { v4 as uuidv4 } from 'uuid';

function useSharedState(activityName) {
  // LAST EVENT IS THE MAIN PREVIOUS STATE
  // const { lastEvent, event, addEvent } = useEventContext();
  const { groupId, dialogId, lastAction } = useDialogContext();
  const { registerEvent } = useActivityContext();
  // console.log("In here, dialogId is "+JSON.stringify(dialogId) + " dialog is " + JSON.stringify(dialog))
  // this is the payload pushed in events
  const [sharedState, setSharedStateLocal] = useState({});

  // global syncState function
  const syncState = () => {
    registerEvent(sharedState);
  };

  // this is the primary guy to use
  const setSharedState = newState => {
    if (typeof newState == 'function') {
      setSharedStateLocal(currentState => ({ ...newState(currentState), fromEvent: false }));
    } else {
      setSharedStateLocal(currentState => ({ ...currentState, ...newState, fromEvent: false }));
    }
  };

  const getFromSharedState = ({ stateName, defaultValue }) => {
    let stateValue =
      sharedState == null ? defaultValue : stateName in sharedState ? sharedState[stateName] : defaultValue;
    let updateStateValue = value => {
      // setIsLocalEvent(true);
      setSharedState(currentState => {
        return { ...currentState, [stateName]: value };
      });
    };
    return [stateValue, updateStateValue];
  };

  useEffect(() => {
    setSharedState({});
  }, []);

  // init
  useEffect(() => {
    if (!lastAction.isLocalEvent) {
      setSharedStateLocal({ ...lastAction.payload, fromEvent: true });
    } else {
    }
  }, [lastAction]);

  // init
  useEffect(() => {
    if (!sharedState.fromEvent) {
      syncState();
    }
  }, [sharedState]);

  return { sharedState, getFromSharedState, setSharedState };
}

export default useSharedState;
