import { Button, Card, CardActions, CardContent, CardHeader, Chip, Grid, Paper, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { AuthContext } from '../../../contexts/auth';
import { auth } from '../../../services/firebase';

import ActionButton from '../UI/ActionButton.js';
import ModalButton from '../UI/ModalButton.js';
import ModalWrapper from '../UI/ModalWrapper';
import PageHeading from '../UI/PageHeading';
import ScreenLayout from '../UI/ScreenLayout';
import EditName from './EditName.js';

import NewGroup from '../Groups/NewGroup.js';
import GroupList from '../Groups/GroupList.js';

export default function AccountPage() {
  const [loading, setLoading] = useState(false);
  // const classes = useStyles();
  const history = useHistory();

  const { userInfo, groupList } = useContext(AuthContext);

  return (
    <ScreenLayout>
      {/* <Header /> */}
      <PageHeading title={'Welcome To Dialography'} />

      <Grid container spacing={5} direction="row" alignItems="center">
        {/* {J} */}
        <Grid item style={{ width: '100%' }}>
          <Button fullWidth color="primary" variant="contained" onClick={() => history.push('/new_dialog')}>
            Start A Dialog
          </Button>
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <Typography variant="h4">Your Circles</Typography>

          <Paper style={{ padding: 10, margin: 10 }}>
            <GroupList groupList={groupList} />

            <ModalWrapper
              title="Make A New Circle"
              buttonComponent={
                <Button fullWidth variant="outlined">
                  Create A New Circle
                </Button>
              }
            >
              <NewGroup />
            </ModalWrapper>
          </Paper>
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <Paper style={{ padding: 10, margin: 10 }}>
            <Typography variant="h4">Your Account</Typography>

            <ActionButton text={'Manage Photos'} onClick={() => history.push('/managealbums')} />

            <ModalButton text="Manage Account" title="Change Your Name">
              <EditName />
            </ModalButton>

            <ActionButton
              text={'Logout'}
              onClick={async () => {
                await auth.signOut();
                window.location.assign('/login');
                return;
              }}
            />
          </Paper>
        </Grid>
      </Grid>
      {/* <Footer /> */}
    </ScreenLayout>
  );
}
