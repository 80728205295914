import React, { useState, useEffect } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  InputAdornment,
  IconButton,
  TextField,
  responsiveFontSizes,
} from '@material-ui/core';
import ReactPlayer from 'react-player';
import { auth, firestore } from '../../../services/firebase';
import { useCollection } from 'react-firebase-hooks/firestore';

import { useLocation } from 'react-router';
const useStyles = makeStyles(theme => ({
  setupHeader: {
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  box: {
    margin: 'auto',
    marginTop: '30px',
    color: theme.palette.primary.main,
    marginBotton: '30px',
    maxWidth: '700px',
  },
  paper: {
    padding: '15px',
    paddingTop: '0px',
  },
  createButton: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    color: 'white',
    marginBotton: '10px',
    marginTop: '10px',
  },
  createButtonDisabled: {
    width: '100%',
    opacity: '05',
    color: theme.palette.primary.main,
    marginBotton: '10px',
    marginTop: '10px',
  },
  backdropProgress: {
    zIndex: '300',
    width: '100%',
    position: 'absolute',
    top: '0',
  },
  backdrop: {
    zIndex: '300',
  },
  alert: {
    marginBottom: '10px',
  },
}));

const VideoList = () => {
  const classes = useStyles();

  const [activeVideoURL, setActiveVideoURL] = useState('');

  const [videoList, loading, error] = useCollection(firestore.collection('twiliocompositions'), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const getVideoURL = async compositionId => {
    fetch(
      'https://us-central1-dialography-29b8b.cloudfunctions.net/getCompositionDownloadURL?compositionId=' +
        compositionId
    )
      .then(response => response.text())
      .then(x => setActiveVideoURL(x));
  };

  if (!videoList) {
    return <div>loading</div>;
  }

  return (
    <Box width="90%" className={classes.box}>
      <Paper className={classes.paper}>
        <h1>Video Gallery</h1>
        <p>
          {' '}
          this lists all the compositions in our twiliocompositions firestore collection. If there is an embarassing one
          you want removed, delete it from the database.
        </p>
        <p>ISSUE: getting repeated documents sometimes, something to do with UseEffect or some crazyness</p>
        <a href="https://console.firebase.google.com/u/0/project/dialography-29b8b/firestore/data/~2Ftwiliocompositions~2FCJ365e2f3ba4150f9087ad962948166eea">
          link to firebase firestore collection with json docs (delete them here)
        </a>{' '}
        <br />
        <a href="https://console.firebase.google.com/u/0/project/dialography-29b8b/storage/dialography-29b8b.appspot.com/files">
          link to firebase cloud storage with video files
        </a>{' '}
        <br />
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {videoList.docs.map(v => (
            <div key={v['compositionSid']}>
              <hr />
              Composition Document (from twiliocompositions) <br />
              <small>
                <pre>{JSON.stringify(v.data(), null, 2)}</pre>
              </small>
              <Button onClick={() => getVideoURL(v.data()['compositionSid'])}>Click To Play!</Button>
              <hr />
            </div>
          ))}
        </Grid>
        <Grid>
          {activeVideoURL ? (
            <div>
              <h2> Here is the active video</h2>
              <p>Active Video Watch URL {activeVideoURL}</p>
              <ReactPlayer url={activeVideoURL} controls={true} />
            </div>
          ) : null}
        </Grid>
      </Paper>
    </Box>
  );
};
export default VideoList;
