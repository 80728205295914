import React, { useEffect, useRef, useState } from 'react';
import useEventContext from '../../../hooks/useEventContext/useEventContext';
import useActivityContext from '../../../hooks/useActivityContext/useActivityContext';
import { v4 as uuidv4 } from 'uuid';
import useDialogContext from '../../../hooks/useDialogContext/useDialogContext';

import Survey from './components/Survey';
import ListView from '../General/ListView.js';
import ActionButton from '../General/ActionButton.js';
import useSharedState from '../useSharedState.js';
import LoadFireStoreCollection from '../loadFireStoreCollection.js';
import SurveyCard from './SurveyCard.js';
import { functions } from '../../../../services/firebase';
const getDialogSurveyList = functions.httpsCallable('getDialogSurveyList');

function SurveyActivity(props) {
  const { sharedState, getFromSharedState, setSharedState } = useSharedState('SurveyActivity');
  const { isHost, dialog } = useDialogContext();

  const [surveyList, setSurveyList] = getFromSharedState({ stateName: 'surveyList', defaultValue: null });

  const [activeSurvey, setActiveSurvey] = getFromSharedState({ stateName: 'activeSurvey', defaultValue: null });
  const [lastAction, setLastAction] = getFromSharedState({ stateName: 'lastAction', defaultValue: {} });

  // ACTION HANDLING... USED TO CAPTURE AND UPDATE RESPONSES IN THE SURVEY
  const [lastActionTime, setLastActionTime] = useState(0);
  const [actionHandlers, setActionHandlers] = useState({
    debug: action => console.log('new action'),
  });
  const registerActionHandler = (name, func) => {
    actionHandlers[name] = func;
    setActionHandlers(actionHandlers);
  };

  // this is the main guy, use it to add an action
  const registerAction = async action => {
    function clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    }
    let cleanAction = clean(action);
    setLastActionTime(Date.now());

    setLastAction({ timestamp: Date.now(), action: cleanAction });
  };

  useEffect(() => {
    if (lastAction['timestamp'] > lastActionTime) {
      Object.keys(actionHandlers).forEach(ahkey => {
        actionHandlers[ahkey](lastAction);
      });
      setLastActionTime(lastAction['timestamp']);
    }
  }, [lastAction]);

  //

  // let surveyList = LoadFireStoreCollection('surveys');

  const getSurveyList = async () => {
    let res = await getDialogSurveyList({ dialogId: dialog.id });
    setSurveyList(res.data.surveyList);
  };
  useEffect(() => {
    // the host is resposible for getting the questions
    if (isHost && !surveyList) {
      getSurveyList();
    }
  }, [dialog]);

  if (activeSurvey) {
    return (
      <div style={{ backgroundColor: 'white', overflowY: 'scroll', width: '100%' }}>
        <small>
          <button onClick={() => setActiveSurvey(null)}>close survey</button>
        </small>

        <Survey
          key={activeSurvey.name}
          form={activeSurvey}
          embed={true}
          onFinish={() => setActiveSurvey(null)}
          registerAction={registerAction}
          registerActionHandler={registerActionHandler}
        />
      </div>
    );
  }
  if (!surveyList) {
    return <div>loading survey..</div>;
  }

  function IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  return (
    <div style={{ backgroundColor: 'white', overflowY: 'scroll', width: '100%' }}>
      <small>
        <button
          onClick={() => {
            let res = window.prompt('Paste Survey JSON');

            if (IsJsonString(res)) {
              setSurveyList([...surveyList, JSON.parse(res)]);
              alert('added');
            } else {
              alert('sorry not valid JSON');
            }
          }}
        >
          add new survey
        </button>
        <button
          onClick={() => {
            console.log(surveyList);
          }}
        >
          see survey list JSON
        </button>
      </small>

      <ListView
        objectList={surveyList}
        getFromSharedState={getFromSharedState}
        showObjectComponent={survey => (
          <SurveyCard
            key={survey.index}
            survey={survey}
            viewCallBack={() => {
              setActiveSurvey(survey);
            }}
          />
        )}
      />
    </div>
  );
}
export default SurveyActivity;
