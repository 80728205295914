import React, { useState, useEffect, useContext } from 'react';
import { auth, googleAuthProvider } from '../../../services/firebase';
import { useLocation, useHistory } from 'react-router';
import { CssBaseline, TextField, Checkbox, Link, Grid, Typography, Container, Button, Hidden } from '@material-ui/core';
import { AuthContext } from '../../../contexts/auth';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const useStyles = makeStyles(theme => ({
  login: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    margin: 'auto',
    width: '100%',
    maxWidth: '1440px',
    paddingBottom: '100px',
    [theme.breakpoints.between('md', 'xl')]: {
      paddingLeft: '125px',
    },
  },
  leftPane: {
    paddingLeft: '10px',
    paddingRight: '10px',
    [theme.breakpoints.between('md', 'xl')]: {
      paddingTop: '100px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: '50px',
    },
  },
  signInMediumText: {
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '24px',
      lineHeight: '28px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '16px',
      lineHeight: '20px',
      paddingLeft: '5px',
    },

    color: '#000000',
  },
  signInSmallText: {
    fontSize: '20px',
    lineHeight: '23px',
    color: '#FFFFFF',
  },
  neutral400: {
    color: '#737581',
  },
  smallTextBold: {
    fontWeight: '600',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '18px',
      lineHeight: '21px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '16px',
      lineHeight: '19px',
      paddingRight: '6px',
    },
  },
  largeTextBold: {
    fontWeight: '600',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '42px',
      lineHeight: '49px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '32px',
      lineHeight: '37px',
    },
    textAlign: 'center',

    color: '#000000',
  },
  signUpHero: {
    backgroundRepeat: 'no-repeat',
    background: 'url(signup_hero.jpg)',
    width: '572px',
    height: '431px',
  },
  inputbox: {
    width: '93%',

    '& .MuiOutlinedInput-input': {
      color: '#2D82B7',
    },
    '& .MuiInputLabel-root': {
      color: '#737581',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D82B7',
      borderWith: '10px',
      borderRadius: '0px',
    },
    '&:hover .MuiOutlinedInput-input': {
      color: '#2D82B7',
    },
    '&:hover .MuiInputLabel-root': {
      color: '#2D82B7',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D82B7',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D82B7',
    },
    [theme.breakpoints.between('md', 'xl')]: {
      margin: '0px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '5px',
    },
  },
  createAccount: {
    color: '#2D82B7',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  signInButton: {
    color: '#FFFFFF',
    backgroundColor: '#2D82B7',
    borderRadius: '0px',
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '20px',
      fontWeight: 'normal',
      lineHeight: '23px',
      width: '136px',
      height: '60px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '19px',
      width: '105px',
      height: '40px',
    },
  },
}));

export default function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const signInWithGoogle = () => {
    auth.signInWithPopup(googleAuthProvider)

  }

  // We Will Watch AUTH, if it changes kick this to account
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      // alert("Got user "+JSON.stringify(currentUser))
      history.push('/account');
    }
  }, [currentUser]);

  const [loginErrors, setLoginErrors] = useState({
    email: false,
    unknown: false,
  });
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const isValid = () => email.trim() && password.trim();
  const handleLogin = async () => {
    try {
      const user = await auth.signInWithEmailAndPassword(email, password);

      // check if they have a from state
      //if (location.state && location.state['from']) {
      //history.push(location.state['from']['pathname']);
      //} else {
      history.push('/account');
      //}

      return;
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setLoading(false);
        setLoginErrors({
          email: false,
          unknown: false,
          password: true,
        });
      } else if (error.code === 'auth/user-not-found') {
        setLoading(false);
        setLoginErrors({
          password: false,
          email: true,
        });
      }
    }
  };
  return (
    <div>
      <Header />
      <Grid container className={classes.login}>
        <Grid item container md={6} className={classes.leftPane}>
          <Grid item>
            <Typography className={classes.largeTextBold}>Welcome Back</Typography>
          </Grid>
          <Grid item container>
            <Grid xs={7} item className={classes.signInMediumText}>
              Sign In
            </Grid>
            <Grid xs={5} item className={classes.smallTextBold}>
              or{' '}
              <a href="/join_beta" className={classes.createAccount}>
                create account
              </a>
            </Grid>
          </Grid>

          {/* <Grid item xs={12}>
            <Button fullWidth size="large" onClick={signInWithGoogle} variant="contained" color="primary">
              <i className="fab fa-google"></i>Sign In With Google
            </Button>
          </Grid> */}

          <Grid item xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              className={classes.inputbox}
              required
              id="email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              className={classes.inputbox}
              required
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item container>
            <Grid xs={8} item className={classes.smallTextBold}>
              <Checkbox defaultChecked style={{ color: '#2D82B7' }} /> Remember me
            </Grid>
            <Grid xs={4} item>
              <Hidden only={['md', 'lg', 'xl']}>
                <Button
                  variant="contained"
                  className={classes.signInButton}
                  size="small"
                  disableElevation
                  onClick={handleLogin}
                >
                  Sign In
                </Button>
              </Hidden>
              <Hidden only={['xs', 'sm']}>
                <Button
                  variant="contained"
                  className={classes.signInButton}
                  size="large"
                  disableElevation
                  onClick={handleLogin}
                >
                  Sign In
                </Button>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
        <Hidden only={['xs', 'sm']}>
          <Grid item md={6} className={classes.signUpHero}></Grid>
        </Hidden>
      </Grid>
      <Footer />
    </div>
  );
}
