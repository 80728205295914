import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import { firestore, functions } from '../../../services/firebase';
import { AuthContext } from '../../../contexts/auth';

export const DialogContext = createContext();

export function DialogProvider({ children }) {
  // gives access to the underlying dialog object
  // handles actions that are stored as a subcollection
  // also registers users as they connect

  const { currentUser, userInfo } = useContext(AuthContext);
  let uid = currentUser.uid;

  const { dialogId } = useParams();
  const [dialog, set_dialog] = useState({});

  const [groupId, set_groupId] = useState('_');
  const [group, set_group] = useState({});

  const [actions, set_actions] = useState([]);
  const [lastAction, set_lastAction] = useState({});

  let isHost = uid == dialog.hostId;

  const dialogRef = firestore.doc('dialogs/' + dialogId);
  const [dialogDoc, dialogLoading, dialogError] = useDocument(dialogRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const groupRef = firestore.doc('groups/' + groupId);
  const [groupDoc, groupLoading, groupError] = useDocument(groupRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const dialogActionsRef = firestore
    .collection('dialogs')
    .doc(dialogId)
    .collection('actions')
    .orderBy('timestamp', 'desc')
    .limit(1);

  const [dialogActionDocs, events_loading, events_error] = useCollection(dialogActionsRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const addAction = async action => {
    let actionRef = firestore
      .collection('dialogs')
      .doc(dialogId)
      .collection('actions')
      .doc();
    let actionDoc = { ...action };
    actionDoc['timestamp'] = Date.now();
    actionDoc['userId'] = uid;
    actionDoc['id'] = actionRef.id;
    actionDoc['dialogId'] = dialogId;
    actionDoc['groupId'] = dialog.groupId ?? 0;
    await actionRef.set(actionDoc);
    return actionRef.id;
  };

  const setParticipants = async participantList => {
    participantList.push({ id: uid, name: userInfo.name }); // add this user to the participant IDs
    let participantIds = participantList.map(x => x.id);
    // DETERMINE THE HOST
    let hostId = dialog.creatorId;
    if (!participantIds.includes(dialog.creatorId)) {
      let sortedParticipants = participantIds.sort();
      let hostId = sortedParticipants[0];
    }

    await dialogRef.update({ participantIds: participantIds, participantList: participantList, hostId: hostId });
    return;
  };

  useEffect(() => {
    if (dialogDoc) {
      console.log('New Dialog Data');
      let dialogData = dialogDoc.data();
      console.log(JSON.stringify(dialogData, false, 2));
      set_dialog(dialogData);
      set_groupId(dialogData.groupId);

      // HANDLE THE UPDATED DATA
      if (dialogData.knocks) {
        console.log('Have knocks');

        // get all the IDS that are not in access
        let knockIds = Object.keys(dialogData.knocks).filter(x => !(x in dialogData.access));
        for (var i = 0; i < knockIds.length; i++) {
          let knockUID = knockIds[i];
          let knockInfo = dialogData.knocks[knockUID];
          // TODO: check if already denied?
          let res = window.confirm('Let ' + knockInfo['name'] + 'in?');
          if (res) {
            dialogRef.set({ access: { [knockUID]: true } }, { merge: true });
          }
        }
      }
    }
  }, [dialogDoc]);

  useEffect(() => {
    if (groupDoc) {
      let groupData = groupDoc.data();
      set_group({ id: groupId, ...groupData });
    }
  }, [groupDoc]);

  useEffect(() => {
    if (dialogActionDocs && dialogActionDocs.docs && dialogActionDocs.docs.length > 0) {
      let lastActionDoc = dialogActionDocs.docs[0];
      let lastActionData = { id: lastActionDoc.id, ...lastActionDoc.data() };
      lastActionData['isLocalEvent'] = lastActionData['userId'] == uid;

      set_actions(x => [lastActionData, ...x]);
      set_lastAction(lastActionData);
    }
  }, [dialogActionDocs]);

  return (
    <DialogContext.Provider
      value={{ userId: uid, isHost, dialogId, dialog, groupId, group, actions, lastAction, addAction, setParticipants }}
    >
      {children}
    </DialogContext.Provider>
  );
}
