import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core/';
import useActivityContext from '../../../hooks/useActivityContext/useActivityContext';
import useEventContext from '../../../hooks/useEventContext/useEventContext';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import { Grid, Box, Button } from '@mui/material';

interface ActivityListProps {}

const useStyles = makeStyles((theme: Theme) => ({
  activity: {
    width: '190px',
    display: 'inline',
    cursor: 'pointer',
  },
  logo_box: {
    position: 'relative',
    display: 'flex',
    margin: 'auto',
    alignItems: 'bottom',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '150px',
    height: '130px',
    maxWidth: '200px',
  },
  button_box: {
    color: '#2D82B7',
    backgroundColor: '#FFFFFF',
    border: '1px solid #2D82B7',
    fontSize: '13px',
    fontWeight: 'normal',
    textAlign: 'center',
    lineHeight: '20px',
    padding: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginTop: '5px',
    marginLeft: '10px',
    marginRight: '10px',
  },
}));

export default function ActivityList(props) {
  const { activities, currentActivity, openActivity } = useActivityContext();
  const classes = useStyles();

  return (
    // <div style={{ display: 'inline', width:"100%", overflowY: 'scroll', marginTop: '10px' }}>
    <div style={{ width: '100%', height: props.height }}>
      <Grid container style={{ width: '100%', overflowY: 'scroll' }}>
        {Object.keys(activities).map((key, index) => {
          return (
            <Grid
              // className={classes.activity}
              key={activities[key].component_name}
              onClick={() => openActivity({ name: activities[key].component_name })}
            >
              <div className={classes.logo_box}>
                <img style={{ maxWidth: '200px' }} src={'/' + activities[key].logo} />
              </div>
              <Typography
                className={classes.button_box}
                style={{ color: activities[key].color, border: '1px solid ' + activities[key].color }}
              >
                {activities[key].text}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
