import { makeStyles } from '@material-ui/core/styles';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import Pause from '@material-ui/icons/Pause';
import PlayArrow from '@material-ui/icons/PlayArrow';
import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import { functions } from '../../../../services/firebase';
import ListView from '../General/ListView.js';
import useSharedState from '../useSharedState.js';
import VideoCard from './videoCard.js';

const searchYouTube = functions.httpsCallable('searchYouTube');

const useStyles = makeStyles(theme => ({
  pageMat: {
    display: 'flex',
    background: 'rgba(0, 0, 0, 0.8)',
    width: '100vw',
    height: '100vh',
    zIndex: '1',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    left: '0',
    position: 'fixed',
  },
  objStyle: {
    opacity: '1',
    zIndex: '2',
    background: 'transparent',
    maxWidth: 'calc(vw-100px)',
    maxHeight: 'calc(vh-100px)',
  },
  topButtons: {
    position: 'absolute',
    top: '70px',
    left: '70px',
  },
  bottomButtons: {
    position: 'absolute',
    bottom: '70px',
    left: '70px',
  },
  closePhotoButton: {
    backgroundColor: '#D0454C !important',
    color: '#FFFFFF',
    borderRadius: '0px !important',
    fontSize: '20px',
    fontWeight: 'normal',
    lineHeight: '23px',
    width: '196px',
    height: '55px',
    margin: '5px',
  },
  nextButton: {
    backgroundColor: '#276738 !important',
    color: '#FFFFFF',
    borderRadius: '0px !important',
    fontSize: '14px !important',
    fontWeight: 'normal',
    lineHeight: '23px',
    width: '196px',
    height: '55px',
    margin: '5px !important',
  },
}));

function YoutubeWatcher(props) {
  const classes = useStyles();
  // LAST EVENT IS THE MAIN PREVIOUS STATE
  // const { lastEvent, event, addEvent } = useEventContext();

  const [youtubePlayer, setYoutubePlayer] = useState(null);
  // tracks state of the local player
  const [localPlayerState, setLocalPlayerState] = useState(null);

  // for input text
  const [youtubeInputText, setYoutubeInputText] = useState('');

  // scratch to handle update time
  const [lastUpdateTime, setLastUpdateTime] = useState(false);

  const { sharedState, getFromSharedState, setSharedState } = useSharedState('YoutubeWatch');

  const [lastUpdateAction, setLastUpdateAction] = getFromSharedState({
    stateName: 'lastUpdateAction',
    defaultValue: {},
  });
  const [activeYoutubeID, setActiveYoutubeID] = getFromSharedState({
    stateName: 'activeYoutubeID',
    defaultValue: null,
  });

  // handle the update actions
  useEffect(() => {
    console.log('Got an action: ' + JSON.stringify(lastUpdateAction));
    console.log('Local player state is ' + JSON.stringify(localPlayerState));
    if (youtubePlayer && lastUpdateAction.timestamp && lastUpdateAction.timestamp > lastUpdateTime) {
      // if (lastUpdateAction.action == localPlayerState) {
      //     console.log("Got message, but already action already matches")
      //   // already did it
      // } else {
      if (lastUpdateAction.action == YouTube.PlayerState.PLAYING) {
        console.log('Got a play message');
        youtubePlayer.playVideo();
        setLastUpdateTime(lastUpdateAction.timestamp);
      }
      if (lastUpdateAction.action == YouTube.PlayerState.PAUSED) {
        console.log('Got a pause message');
        youtubePlayer.pauseVideo();
        setLastUpdateTime(lastUpdateAction.timestamp);
      }
      // }
    }
  }, [lastUpdateAction]);

  // Play Handlers
  const handlePlay = () => {
    setLastUpdateAction({ action: YouTube.PlayerState.PLAYING, timestamp: Date.now() });
    setLastUpdateTime(Date.now());
    youtubePlayer.playVideo();
  };
  const handlePause = () => {
    setLastUpdateAction({ action: YouTube.PlayerState.PAUSED, timestamp: Date.now() });
    setLastUpdateTime(Date.now());
    youtubePlayer.pauseVideo();
  };

  // GET Videos FROM FIREBASE
  const [videoList, setVideoList] = useState([{ name: 'Log Drivers', youtubeid: 'upsZZ2s3xv8' }]);
  // const [videoCollection, isLoading, error] = useCollection(firestore.collection('videos'), {
  //   snapshotListenOptions: { includeMetadataChanges: true },
  // });

  // useEffect(() => {
  //   if (videoCollection) {
  //     let videoList = videoCollection.docs.map((p, index) => ({ ...p.data(), index: index }));
  //     alert(JSON.stringify(videoList));
  //     setVideoList(videoList);
  //   }
  // }, [videoCollection, error]);

  if (!activeYoutubeID) {
    return (
      <div style={{ backgroundColor: 'white', overflowY: 'scroll', width: '100%' }}>
        <TextField onChange={e => setYoutubeInputText(e.target.value)} value={youtubeInputText} />
        <Button
          onClick={async () => {
            let res = await searchYouTube({ search: youtubeInputText });
            let videos = res['data']['results']['items'];
            setVideoList(videos);
            // alert(JSON.stringify(res))
          }}
        >
          Click To Load Search Results
        </Button>
        <ListView
          objectList={videoList}
          getFromSharedState={getFromSharedState}
          showObjectComponent={v => (
            <VideoCard key={v.id} video={v} viewCallBack={() => setActiveYoutubeID(v.youtubeid ?? v.id)} />
          )}
        />
      </div>
    );
  }
  return (
    <div className={classes.pageMat}>
      <div className={classes.topButtons}>
        <Button variant="contained" className={classes.closePhotoButton} onClick={() => setActiveYoutubeID(null)}>
          <CancelPresentationIcon style={{ paddingRight: '5px' }} /> Close Video
        </Button>
      </div>
      <div className={classes.objStyle} key={activeYoutubeID}>
        {activeYoutubeID ? (
          <YouTube
            key={activeYoutubeID}
            videoId={activeYoutubeID}
            onReady={event => {
              setYoutubePlayer(event.target);
              // alert('Player ready!');
            }}
            onStateChange={event => {
              console.log('State Changed');
              setLocalPlayerState(event.data);
              if (event.data == YouTube.PlayerState.PLAYING && lastUpdateAction.action != YouTube.PlayerState.PLAYING) {
                setLastUpdateAction({ action: YouTube.PlayerState.PLAYING, timestamp: Date.now() });
                setLastUpdateTime(Date.now());
              }
              if (event.data == YouTube.PlayerState.PAUSED && lastUpdateAction.action != YouTube.PlayerState.PAUSED) {
                setLastUpdateAction({ action: YouTube.PlayerState.PAUSED, timestamp: Date.now() });
                setLastUpdateTime(Date.now());
              }
            }}
            opts={{
              playerVars: {
                autoplay: 0,
                cc_load_policy: 1,
                controls: 0,
                modestbranding: 1,
                enablejsapi: 1,
              },
            }}
          />
        ) : null}
      </div>
      <div className={classes.bottomButtons}>
        <Button variant="contained" className={classes.nextButton} onClick={handlePlay}>
          <PlayArrow fontSize="small" style={{ paddingRight: '5px' }} /> Play Video
        </Button>
        <Button variant="contained" className={classes.nextButton} onClick={handlePause}>
          <Pause style={{ paddingRight: '5px' }} /> Pause
        </Button>
      </div>
    </div>
  );
}
export default YoutubeWatcher;
